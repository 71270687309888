.main {
  text-align: left;
  width: 96%;
  margin: 0 auto;
}

.selectEl {
  width: 60%;
}

.headerRow {
  padding: 10px 0;
}

.buttonEl {
  position: absolute;
  right: 20px;
}

.selectAll {
  margin: 0.5em 0;
}

.data {
  padding-left: 39px;
}

.discounts {
  margin: 0.3em 0 0;
}

.image {
  width: 100%;
  padding: 10px;
  border: 1px var(--main-border-color) dashed;
}
.imageWrap {
  margin: 0 0 1em;
  padding: 0 0 0 24px;
  position: relative;
}
.imageWrap .checkbox {
  left: 0;
  position: absolute;
  top: 0;
}

.mainRow {
  margin-top: 30px;
}

@media all and (min-width: 768px) {
  .data {
    padding-left: 15px;
  }
}

.daysInput {
  padding-bottom: 1.14em !important;
  padding-top: 1.14em !important;
  width: 5em !important;
}

.leadTimeInput {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.leadTimeInput span {
  margin-bottom: 0;
  text-transform: none;
}

.leadTimeInput input {
  margin: 0 0.5em;
}

.leadTimeInput input[aria-invalid='true'] {
  padding-right: 20px !important;
}
.data button,
.leadTimeInput button {
  width: 10em;
}

.leadTimeInput button {
  margin-left: 1em;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.error {
  width: 100%;
  color: #dc3545;
  margin-bottom: 10px;
  margin-top: -10px;
}
