.actionLabel {
  color: #b99d6c;
  display: block;
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin: 0.6em 0 0;
}

.close {
  display: none;
  position: absolute;
  right: 0.1em;
  top: 0.1em;
}

.cnt {
  align-items: center;
  background-color: #eee;
  border: dashed 1px #8f8f8f;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.cnt:hover .close {
  display: block;
}

.error .cnt {
  border-color: #f00;
}
.errorMsg {
  color: #f00;
  margin: 0.2em 0 0;
}

.file {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.filler {
  display: block;
}

.hasFile {
  border: 0 none;
}

.input {
  bottom: 0;
  cursor: pointer;
  left: 0;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
}

.input:disabled {
  cursor: not-allowed;
}

.label {
  color: #9d9e9e;
  display: block;
  font-size: 1em;
  margin: 0;
}

.text {
  color: #9d9e9e;
  font-size: 0.625em;
  letter-spacing: 0.3em;
  padding: 0 0.5em;
  text-align: center;
  text-transform: uppercase;
}

.title {
  font-weight: 600;
}

.wrap {
  position: relative;
}
