.customProps > li {
  margin: 0 0 0 1em;
}

.info {
  margin: 0 0 2em 1em;
}
.info > li {
  margin: 0 0 0.25em;
}

.infoValue {
  margin: 0 0 0 2em;
}

.page .pageMenu {
  margin-bottom: 1em;
  margin-left: -30px;
}

.product {
  margin-bottom: 3em;
}
