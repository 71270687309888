.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.colRight {
  position: relative;
}

.fieldTitle {
  color: #000;
  font-family: 'brandon-grotesque';
  font-size: 0.625em;
  font-weight: normal;
  letter-spacing: 0.15em;
  line-height: 1.6;
  text-transform: uppercase;
}

.field {
  margin-bottom: 16px;
}
.form {
  width: 100%;
}
.form .fieldTitle {
  margin: 0 0 0.5em;
  font-size: 0.563em;
}
.form label {
  font-size: 1em;
}

.leftCol,
.marginBottom {
  margin-bottom: 0.25em;
}

.statusValue {
  background: #fff;
  color: #000;
  padding: 0 1.2em;
}

@media screen and (min-width: 992px) {
  .leftCol {
    margin-bottom: 0;
  }
}
.imagesRow h3 {
  color: #000;
  font-family: 'brandon-grotesque';
  font-size: 0.813em;
  font-weight: normal;
  letter-spacing: 0.05em;
  line-height: 1.6;
  text-transform: uppercase;
}
.orderShippingDetailsRow {
  margin-bottom: 24px;
}

.imageList img {
  max-width: 120px;
  height: auto;
}
.imageListItem {
  max-width: calc(120px + 30px);
}
.imageInputWithList {
  display: flex;
}

/* Dirty hack to increase specificity to bypass bootstrap's ridiculously specific styles */
.proformaButton.proformaButton {
  font-size: 0.575rem;
  margin-top: 8px;
  width: 50%;
}

.proformaFile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proformaFile :global .btn-group {
  width: 100%;
}

.carbCert {
  margin-right: 18px;
}
.packages {
  margin-top: 3em;
  padding-left: 15px;
}
.packages label {
  font-size: 0.625em;
}

.fileInput {
  margin-top: 14px;
}
.fileInputsTitleA {
  text-decoration: underline;
  cursor: pointer;
}
