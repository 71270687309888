.article {
  padding: 0.75em 0;
}

.buttonWrapper {
  text-align: center;
}

.cnt {
  margin-top: 1.5em;
}

.dataList {
  margin: 2.5em 0 1em;
}

.heading {
  align-items: center;
  display: flex;
}
.heading h1 {
  color: #000;
  font-size: 1.125em;
  font-weight: 600;
  letter-spacing: 0.128em;
  line-height: 1.222;
  margin: 0;
  text-transform: uppercase;
}

.noProductsStatus {
}

.productsList {
}

.productsQuantity {
  font-size: 0.625em;
  letter-spacing: 0.129em;
  line-height: 1.6;
  margin: 2.8em 0 0;
}

.status {
  background: #fff;
  color: #000;
  display: inline-block;
  font-size: 0.625em;
  font-weight: 600;
  letter-spacing: 0.15em;
  line-height: 2.2;
  margin: 0 0 0 2.2em;
  padding: 0.2em 1.2em 0;
  text-transform: uppercase;
}
