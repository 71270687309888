.image {
  max-height: 2em;
  max-width: 2em;
}

.input {
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: var(--file-input-bg-color);
  width: 6.5rem;
}

tr.rowColName {
  background: var(--main-bg-color);
}

.rowColName td {
  text-align: center;
}

.select {
  margin-bottom: 5px;
}

.sku {
  align-items: center;
  display: flex;
}
.sku .image {
  margin: 0 0 0 1em;
}

.twoValues {
  margin-top: 12px;
  width: 100%;
}

.types :global(.dropdown-menu) {
  min-width: 25em;
}
.types :global(.dropdown-menu) > li {
  font-size: 0.75em;
}

.verticalMiddle {
  vertical-align: middle !important;
}
