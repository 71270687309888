.buttons {
  margin: 1em 0 0;
}

.control {
  margin: 0.8em 0 0;
}

.input {
  margin: 1em 0 0;
}

.info {
  margin: 1em 0 0;
}

.results {
  margin-top: 1em;
  max-height: 500px;
  overflow-y: auto;
}

.results > div {
  padding: 5px;
}

.results > div {
  border-bottom: 1px solid #666;
}

.results > div.summary {
  padding-bottom: 1em;
  position: sticky;
  top: 0;
  background-color: var(--main-bg-light);
}

.results > div.summary > span {
  margin-right: 1em;
}

.results > div.success {
  background-color: #35b533;
}

.results > div.partially {
  background-color: #ffff8f;
}

.results > div.error {
  background-color: #ff9f9f;
}
