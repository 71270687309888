.productImg {
  display: inline-block;
  height: 5em;
  width: 5em;
  overflow: hidden;
}
.productImg > img {
  max-height: 100%;
  max-width: 100%;
}

.table {
  border: 0 none;
  font-family: 'brandon-grotesque';
}

.table td,
.table thead th {
  border: 0 none;
  vertical-align: middle;
}

.table td {
  color: #9d9e9e;
  font-size: 0.75em;
  letter-spacing: 0.128em;
  line-height: 1.33;
}

.table td.orderNumber {
  color: #000;
  font-size: 0.875em;
  font-weight: 900;
  letter-spacing: 0.129em;
  line-height: 1.57;
}
.table td.orderNumber > a {
  text-decoration: none;
}

.table td.pending {
  color: #000;
}
.table td .price {
  color: #000;
  font-weight: 600;
}
.table td.status {
  font-size: 0.625em;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.6;
  text-transform: uppercase;
}
.table td.working {
  color: #b79961;
}

.table th,
.table tr:nth-child(odd) {
  background-color: transparent;
}
.table thead th {
  color: #9d9e9e;
  font-size: 0.625em;
  font-weight: normal;
  letter-spacing: 0.129em;
  line-height: 1.6;
  text-transform: uppercase;
  width: auto !important;
}
