.button {
  border: 1px solid #000;
  border-radius: 0;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
a.button {
  display: flex;
  align-items: center;
  font-size: 0.75rem !important;
}
.link {
  display: flex;
  align-items: center;
}

.button:hover {
  background: #b79961;
  border-color: #b79961;
}
.button.danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.button.disabled,
.button.disabled:hover {
  background: #6c757d;
  border-color: #6c757d;
  color: #fff;
  cursor: default;
  opacity: 0.65;
}
.defaultColor {
  background: #fff;
  color: #000;
}
.black {
  background-color: #000;
  color: #fff;
  font-weight: 600;
}

.lightBrown,
.lightBrown.button:hover {
  color: #b79961;
}

.thin {
  background: none;
  border: 0 none;
  padding: 0;
}
.thin:hover {
  background: none;
  color: #000;
}
