.hd {
  align-items: center;
  display: flex;
}
.hd > h3 {
  margin: 0;
}

.main {
  margin: 0 auto;
  text-align: left;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: none;
  color: #2d2d2d;
}

.message {
  color: var(--main-darkRed-color);
}

.button {
  margin: 0 0.5em 0.5em 0;
}

.borderBottom {
  display: block;
  margin: 0 -15px 1.5em;
  min-height: 50px;
  padding: 10px 0;
  position: relative;
}
.borderBottom:after,
.buttons:after,
.productList:after {
  border-bottom: 1px var(--main-border-color) dashed;
  bottom: 0;
  content: '';
  left: 15px;
  position: absolute;
  right: 15px;
}

.buttons {
  padding: 0 0 1em;
  position: relative;
}
.buttons:after {
  left: 0;
  right: 0;
}

.pageNav {
  margin: 0 -15px;
}

.productList {
  margin-bottom: 1.5em;
  padding: 0 0 1em;
  position: relative;
}

.vendorActions {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .vendorActions {
    justify-content: flex-start;
    margin-top: 0.5em;
  }
}
