.dropdownToggle {
  padding: 0.625rem 0.75rem;
  border-left: 1px solid #b79961;
}
.dropdownToggle[aria-expanded='true'] {
  background: #b79961 !important;
  border-color: #b79961 !important;
}
.dropdownToggle:hover {
  background: #b79961;
  border-color: #b79961;
}

.black {
  background-color: #000;
  color: #fff;
  font-weight: 600;
}
