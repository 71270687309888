.renewing {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.status {
  font-weight: 600;
}

.table {
  text-transform: uppercase;
  width: auto;
}
.table td {
  vertical-align: middle;
}
