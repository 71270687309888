.image {
  background: #fff;
  border: 1px dashed #000;
  display: flex;
  height: 5.5em;
  justify-content: center;
  padding: 0.5em;
  width: 5.5em;
}
.image > img {
  display: block;
  max-height: 100%;
  max-width: 100%;
}
