:root {
  --main-03-color: rgba(183, 153, 97, 0.3);
  --main-04-color: rgba(183, 153, 97, 0.4);
  --main-07-color: rgba(183, 153, 97, 0.7);
  --main-bg-color: rgba(183, 153, 97, 1);
  --main-green-color: #407d41;
  --main-lightGreen-color: #56c65b;
  --main-success-color: #0c0;
  --main-lightBlue-color: #32aa9d;
  --main-blue-color: #2196f3;
  --main-pink-color: #d9534f;
  --main-red-color: #f63636;
  --main-darkRed-color: #d43f3a;
  --loading-color: rgba(240, 201, 126, 0.2);
  --loading-sec-color: #73603d;
  --loading-brown-color: #3f3521;
  --main-golden: rgb(250, 190, 58);
  --main-border-color: #ababab;
  --file-input-bg-color: #eeeeee;
  --main-text: #2d2d2d;
  --main-disabled-color: #919191;
  --main-bg-light: #f8f9fa;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*padding: 50px;*/
  font-family: 'brandon-grotesque';
  font-size: 16px;
  color: var(--main-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #000;
  text-decoration: underline;
}

a:focus,
a:hover {
  color: var(--main-bg-color);
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

#products,
#artisans {
  margin-top: 60px;
}

#product-image {
  max-width: 600px;
  max-height: 400px;
}

#product-form label.error {
  color: #c00;
}

#product-form input.error {
  border: 1px solid #c00;
}

#productDropdown {
  column-count: 3;
  -moz-column-count: 3;
}

.panel-heading {
  border-color: #eff2f7;
  font-size: 16px;
  font-weight: 300;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: 15px;
}

.task-content {
  margin-bottom: 30px;
}

.tasks-widget .task-list {
  padding: 0;
  margin: 0;
}

.tasks-widget .task-list > li {
  position: relative;
  padding: 10px 5px;
  border-bottom: 1px dashed #eaeaea;
}

ul li {
  list-style: none;
}

.tasks-widget .task-list li > .task-checkbox {
  float: left;
  width: 30px;
}

input[type='checkbox'],
input[type='radio'] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input,
textarea,
select,
button {
  outline: none !important;
  border: 1px solid #666;
}

.btn-success {
  background-color: var(--main-lightGreen-color);
  border-color: var(--main-lightGreen-color);
  color: #ffffff;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid;
  border-radius: 0;
  text-decoration: none;
}

.btn-primary {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  text-transform: uppercase;
}

.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary.hover,
.btn-primary:disabled,
.open > .dropdown-toggle.btn-primary {
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.btn-secondary {
  text-transform: uppercase;
  border: 1px solid #000;
  border-radius: 2px;
}

label.error {
  color: #c00;
}

.form-control {
  border-radius: 0;
}

#typeahead {
  margin: 0;
  padding: 0;
}

.tt-menu {
  /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
  width: 422px;
  margin-top: 12px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
}

#onboardingReview #product-cont .reviewable {
  text-align: center;
}

#onboardingReview #product-cont img {
  max-height: 500px;
}

#onboardingReview #product-cont .reviewable img {
  width: 480px;
  padding: 0 10px;
}

#onboardingReview #product-cont div .actions h4 {
  height: 35px;
  line-height: 35px;
}

#onboardingReview #product-cont div.reviewable {
  padding-bottom: 10px;
  margin-bottom: 20px;
}

#onboardingReview #product-cont .approve span.glyphicon,
#onboardingReview #product-cont .reject span.glyphicon {
  font-size: 50px;
  color: #aaa;
  padding: 10px;
}

#onboardingReview #product-cont span.glyphicon {
  font-size: 35px;
  color: #aaa;
  padding: 10px;
}

#onboardingReview #product-cont span.glyphicon:hover {
  color: #444;
}

#onboardingReview #product-cont .ok span.glyphicon-ok-sign {
  color: var(--main-success-color);
}

#onboardingReview #product-cont .done span.glyphicon-ok-sign {
  color: #00c;
}

#onboardingReview #product-cont .ko span.glyphicon-remove-sign {
  color: #c00;
}

#onboardingReview #product-cont #approve.on span {
  color: var(--main-success-color);
}

#onboardingReview #product-cont #reject.on span {
  color: #c00;
}

#artisans .hasSeparateInvoice {
  display: none;
}

.successText {
  color: var(--main-success-color);
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ div .custom-control-label::before {
  border-color: var(--main-bg-color);
  background-color: var(--main-bg-color);
}

.custom-switch .custom-control-label::before {
  border-radius: 0;
}

.custom-switch .custom-control-label::after {
  border-radius: 0;
}

.valid-feedback {
  color: var(--main-bg-color);
  font-size: 1rem;
}

.was-validated .form-control:valid {
  border-color: var(--main-bg-color);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: var(--main-bg-color);
  box-shadow: 0 0 0 0.2rem var(--main-03-color);
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: var(--main-bg-color);
  box-shadow: 0 0 0 0.2rem var(--main-03-color);
}

.row {
  margin: 0 auto;
  min-width: 100%;
}

.input-group > .form-control-plaintext {
  border-color: var(--main-bg-color);
}

.image {
  width: 100%;
}

.customInput {
  width: 49%;
}

body {
  background-color: var(--main-bg-light);
}

.errorText {
  color: var(--main-red-color);
  margin: 0;
  display: block;
  position: relative;
  bottom: 15px;
  right: 25px;
  font-size: 80%;
}

.paintItRed {
  color: var(--main-red-color);
}

.labelText {
  font-size: 80%;
  position: relative;
  bottom: 15px;
  left: 20%;
}

h2 {
  margin-top: 1em;
}

h1.mod2,
h2.mod1 {
  color: #000;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

label {
  color: #000;
  font-size: 0.75em;
  font-weight: 500;
  letter-spacing: 0.1125em;
  text-transform: uppercase;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: 1px solid #f7f7f7;
}

td,
th {
  text-align: left;
  padding: 15px 5px;
}

textarea {
  min-height: 9.375em;
}

th {
  background-color: var(--main-bg-color);
  text-align: left;
}

tr:nth-child(odd) {
  background-color: var(--main-04-color);
}

ul {
  padding: 0;
}

.App {
  text-align: center;
}

.btn:disabled {
  cursor: default;
}

.custom-control {
  align-items: center;
  display: flex;
  cursor: pointer;
}
.custom-control .custom-control-label {
  cursor: pointer;
}
.custom-control .custom-control-input {
  margin: 0;
}
.custom-control .custom-control-label::before {
  top: 0;
}

.form-control {
  /* color: #9D9E9E; */
  color: #555;
}

.innerPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  padding-bottom: 3em;
  text-align: left;
  width: 96%;
}

.noMarginTop {
  margin-top: 0;
}

.page-link,
.page-link:hover {
  color: #000;
}
.page-item.active .page-link,
.page-item.active.disabled .page-link {
  color: #fff;
}
.page-item.active .page-link {
  background-color: #b79961;
  border-color: #b79961;
  cursor: default;
}

.rowAlignBottom > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.rowWide {
  margin-left: -15px;
  margin-right: -15px;
}

.Button_button__24i6T {
  border: 1px solid #000;
  border-radius: 0;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
a.Button_button__24i6T {
  display: flex;
  align-items: center;
  font-size: 0.75rem !important;
}
.Button_link__3Dnhp {
  display: flex;
  align-items: center;
}

.Button_button__24i6T:hover {
  background: #b79961;
  border-color: #b79961;
}
.Button_button__24i6T.Button_danger__sK2KX {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.Button_button__24i6T.Button_disabled__1S-MG,
.Button_button__24i6T.Button_disabled__1S-MG:hover {
  background: #6c757d;
  border-color: #6c757d;
  color: #fff;
  cursor: default;
  opacity: 0.65;
}
.Button_defaultColor__oOCy_ {
  background: #fff;
  color: #000;
}
.Button_black__1YELc {
  background-color: #000;
  color: #fff;
  font-weight: 600;
}

.Button_lightBrown__2YS4w,
.Button_lightBrown__2YS4w.Button_button__24i6T:hover {
  color: #b79961;
}

.Button_thin__3ueBi {
  background: none;
  border: 0 none;
  padding: 0;
}
.Button_thin__3ueBi:hover {
  background: none;
  color: #000;
}

.ButtonGroup_buttonGroup__2_ShW .btn {
  border-radius: 0;
}

.Close_close__2U_N0 {
  display: inline-block;
  position: relative;
}
.Close_close__2U_N0:after {
  background-color: #fff;
  content: '';
  height: 50%;
  left: 25%;
  position: absolute;
  top: 25%;
  width: 50%;
}

.Close_icon__FYlA6 {
  color: #000;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.ThinIconBtn_btn__La1ll,
.ThinIconBtn_icon__3um2S {
  align-items: center;
  display: inline-flex;
}

.ThinIconBtn_btn__La1ll {
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.5;
}

.ThinIconBtn_icon__3um2S {
  margin: 0 0 0 0.1em;
}

.CloseFrame_close__U35FO {
  display: none;
  position: absolute;
  right: 0.1em;
  top: 0.1em;
}

.CloseFrame_icon__3tN_B {
  display: inline-block;
  position: relative;
}

.CloseFrame_icon__3tN_B > img {
  max-width: 100%;
}

.CloseFrame_icon__3tN_B:hover > .CloseFrame_close__U35FO {
  display: block;
}

.Dropdown_item__13Fku {
  text-decoration: none;
}

.FieldsList_alignBottom__nhvz9 > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.FieldsList_field__26gAH {
  border-color: var(--main-bg-color);
}

.FieldsList_list__2ywnb {
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
}
.FieldsList_list__2ywnb > div {
  margin: 0 0 2em;
}
.FieldsList_list__2ywnb label {
  margin: 0 0 0.3125em;
}

.FieldsList_list__2ywnb > .FieldsList_title__2nxO8 {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.FieldsList_list__2ywnb > .FieldsList_title__2nxO8 .FieldsList_labelAfter__3sIKz {
  display: inline-flex;
  margin-left: 0.3em;
}

.FieldsList_list__2ywnb.FieldsList_topMarginLess__3vW7g > div {
  margin-bottom: 1em;
}

.FieldsList_range__O-gYV {
  display: flex;
  flex-wrap: wrap;
}

.FieldsList_range__O-gYV > div {
  width: 47%;
}

.FieldsList_range__O-gYV > span {
  width: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Input_feedback__19cki {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
}
.Input_feedbackWrap__362cE {
  position: relative;
}

.Input_horizontal__8IieZ,
.Input_horizontal__8IieZ > .Input_input__2oFLQ {
  align-items: center;
  display: flex;
}

.Input_horizontal__8IieZ .Input_reversed__3L4XD > .Input_inputField__20knD {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.Input_horizontal__8IieZ .Input_reversed__3L4XD > .Input_inputField__20knD label {
  margin-bottom: 0;
}
.Input_horizontal__8IieZ label.Input_label__ORMkR {
  font-size: 1em;
  margin: 0.1em 0 0;
  text-transform: none;
}
.Input_horizontal__8IieZ input {
  margin: 0 0 0 0.4em;
  position: static;
  width: auto;
}
.Input_horizontal__8IieZ .Input_reversed__3L4XD .Input_inputField__20knD > input,
.Input_horizontal__8IieZ .Input_reversed__3L4XD .Input_inputField__20knD .Input_inputWrap__n6twQ {
  margin: 0 0.4em 0 0;
}

.Input_horizontal__8IieZ .Input_nextNode__YKvLS {
  margin: 0 0 0 0.4em;
}

.Input_input__2oFLQ input,
.Input_input__2oFLQ select,
.Input_input__2oFLQ textarea {
  background-color: #f1f2f2;
  border-color: #f1f2f2;
  font-size: 0.875em;
  height: auto;
  letter-spacing: 0.072em;
  line-height: 1.15;
  min-width: 6em;
  padding: 1.36em 1.14em;
}

.Input_input__2oFLQ input::placeholder {
  color: #ccc;
}

.Input_input__2oFLQ input {
  height: 56px;
}

.Input_input__2oFLQ input[type='checkbox'],
.Input_input__2oFLQ input[type='radio'] {
  height: auto;
  min-width: auto;
}
.Input_input__2oFLQ select {
  padding-bottom: 1.14em;
  padding-top: 1.14em;
}

.Input_inputField__20knD {
  width: 100%;
}

.Input_inputWrap__n6twQ {
  position: relative;
}
.Input_inputWrap__n6twQ > input {
  margin: 0;
  padding-right: 1.25em;
}

label.Input_label__ORMkR {
  margin: 0 0 1em;
}

.Input_label__ORMkR {
  display: flex;
  align-items: center;
}

.Input_labelAfter__b8tpQ {
  margin: 0 0 0 0.3em;
}

.Input_numberChanges__3SgD- {
  bottom: 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  padding: 0 0.25em 0 0;
  position: absolute;
  right: 0;
  top: 0;
}
.Input_numberChangesBtn__3kZg4 {
  cursor: pointer;
  width: 0.75em;
}
.Input_numberChangesDisabled__2M4zC .Input_numberChangesBtn__3kZg4 {
  color: #aeaeae;
}

.Input_valueLengthLimit__3dZth {
  margin: 0 0 0 0.5em;
}

.Input_withValueLengthLimit__3m_xv {
  display: flex;
}

.Spinner_bg__1AbkE,
.Spinner_bg__1AbkE:after {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.Spinner_bg__1AbkE {
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.Spinner_bg__1AbkE:after {
  content: '';
}

.Spinner_bgWithColor__1M_n8:after {
  background-color: var(--loading-color);
}

.Spinner_spinnerDown__2goag {
  align-items: flex-end;
}

.Spinner_wrap__CDPq0 {
  position: relative;
}

.InputFile_actionLabel__1b3KO {
  color: #b99d6c;
  display: block;
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 0.15em;
  margin: 0.6em 0 0;
}

.InputFile_close__3HRme {
  display: none;
  position: absolute;
  right: 0.1em;
  top: 0.1em;
}

.InputFile_cnt__U9cZd {
  align-items: center;
  background-color: #eee;
  border: dashed 1px #8f8f8f;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.InputFile_cnt__U9cZd:hover .InputFile_close__3HRme {
  display: block;
}

.InputFile_error__3Qpke .InputFile_cnt__U9cZd {
  border-color: #f00;
}
.InputFile_errorMsg__24mui {
  color: #f00;
  margin: 0.2em 0 0;
}

.InputFile_file__3kFvd {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.InputFile_filler__1cpUC {
  display: block;
}

.InputFile_hasFile__2idX_ {
  border: 0 none;
}

.InputFile_input__3QzXR {
  bottom: 0;
  cursor: pointer;
  left: 0;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
}

.InputFile_input__3QzXR:disabled {
  cursor: not-allowed;
}

.InputFile_label__JX0bV {
  color: #9d9e9e;
  display: block;
  font-size: 1em;
  margin: 0;
}

.InputFile_text__3HZ-v {
  color: #9d9e9e;
  font-size: 0.625em;
  letter-spacing: 0.3em;
  padding: 0 0.5em;
  text-align: center;
  text-transform: uppercase;
}

.InputFile_title__3MiNh {
  font-weight: 600;
}

.InputFile_wrap__24uXD {
  position: relative;
}

.ProductPackaging_wrapper__3f3ZK h2 {
  margin-bottom: 2em;
  line-height: 1;
}

.ProductPackaging_tooltip__2XHgi {
  margin-left: 0.3em;
  display: inline-flex;
}

.ProductPackaging_button__Uvrb9 {
  bottom: 2em;
  font-weight: 600;
  letter-spacing: 0.12em;
  border: none;
  background-color: transparent;
  padding: 0;
  margin-bottom: 0.5em;
  color: #b79961;
}

.ProductPackaging_button__Uvrb9:hover,
.ProductPackaging_button__Uvrb9:active,
.ProductPackaging_button__Uvrb9:focus {
  color: #000 !important;
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
}

.ProductPackaging_button__Uvrb9 svg {
  height: 1.5em;
  width: 1.5em;
  color: #b79961;
}

.ProductPackaging_button__Uvrb9:hover svg {
  color: black;
}

.ProductPackaging_colLead__1YlbV {
  display: flex;
  align-items: center;
}

.ProductPackaging_parcelInput__2QsAS {
  margin-bottom: 2em;
  margin-right: 14px;
}

.ProductPackaging_parcelInputLight__N3-Aa input {
  background-color: white;
}

.ProductPackaging_parcelName__RG3ty {
  color: #000;
  font-size: 0.85em;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-right: 1em;
  text-transform: uppercase;
  white-space: nowrap;
}

.ProductPackaging_row__3KOC1 {
  position: relative;

  max-width: 700px;
}

.ProductPackaging_row__3KOC1 > div:first-child {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr 6fr 1fr;
}

.ProductPackaging_amountCurrency__3cJQQ {
  margin-bottom: 2em;
  margin-top: -1em;
}

.ProductPackaging_row__3KOC1 label {
  color: #9d9e9e;
  font-size: 0.625em;
}

.ProductPackaging_title__2TfqP {
  display: flex;
}

.ProductPackaging_row__3KOC1 label.ProductPackaging_title__2TfqP {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 575px) {
  .ProductPackaging_row__3KOC1 {
    display: block;
    margin-bottom: 3em;
  }

  .ProductPackaging_parcelName__RG3ty {
    margin-bottom: 1em !important;
  }
}

.Select_wrap__1fABt {
  cursor: pointer;
}

.Select_wrapDisabled__1ciE- {
  cursor: default;
}

.Filters_filters__1g5Lf {
  display: flex;
  flex-wrap: wrap;
}
.Filters_filter__3LulV {
  width: 20%;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.LabelValueSelect_data__2zH89 {
  align-items: center;
  display: flex;
}

.LabelValueSelect_icon__kYmSl {
  margin: 0 0 0 0;
}

.LabelValueSelect_label__1e8lv,
.LabelValueSelect_value__2m7gJ {
  font-size: 0.625em;
  text-transform: uppercase;
}

.LabelValueSelect_label__1e8lv {
  color: #9d9e9e;
  letter-spacing: 0.129em;
  line-height: 1.6;
  margin: 0 0 1.4em;
}

.LabelValueSelect_toggler__2a5PU {
  align-items: center;
  color: #b69864;
  cursor: pointer;
  display: flex;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.5;
  text-transform: uppercase;
}

.LabelValueSelect_value__2m7gJ {
  color: #b79961;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 2.2;
  margin: 0 2em 0 0;
}

.LabelValueSelect_wrap__1NYJp {
  font-family: 'brandon-grotesque';
}

.NewOnboarding_button__2giGu {
  cursor: pointer;
  outline: none;
  padding: 11px 30px;
  transition: 0.3s;
}

.NewOnboarding_title__3A5DQ {
  display: inline-block;
  font-size: 24px;
  margin: 0 1em 0 0;
}

.NewOnboarding_wrap__kq03i {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.OrderDetail_article__12VBD {
  padding: 0.75em 0;
}

.OrderDetail_buttonWrapper__2_yVn {
  text-align: center;
}

.OrderDetail_cnt__2T1Ns {
  margin-top: 1.5em;
}

.OrderDetail_dataList__2Ywql {
  margin: 2.5em 0 1em;
}

.OrderDetail_heading__wmcsc {
  align-items: center;
  display: flex;
}
.OrderDetail_heading__wmcsc h1 {
  color: #000;
  font-size: 1.125em;
  font-weight: 600;
  letter-spacing: 0.128em;
  line-height: 1.222;
  margin: 0;
  text-transform: uppercase;
}

.OrderDetail_noProductsStatus__1q7ZD {
}

.OrderDetail_productsList__1q7zm {
}

.OrderDetail_productsQuantity__29yz9 {
  font-size: 0.625em;
  letter-spacing: 0.129em;
  line-height: 1.6;
  margin: 2.8em 0 0;
}

.OrderDetail_status__Atq1e {
  background: #fff;
  color: #000;
  display: inline-block;
  font-size: 0.625em;
  font-weight: 600;
  letter-spacing: 0.15em;
  line-height: 2.2;
  margin: 0 0 0 2.2em;
  padding: 0.2em 1.2em 0;
  text-transform: uppercase;
}

.TitleIconList_icon__DWE1o {
  align-items: center;
  display: flex;
  margin: 0 0 0 0.3em;
}

.TitleIconList_list__3OXms {
  color: #b69864;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.5;
  margin: 0;
  text-transform: uppercase;
}
.TitleIconList_list__3OXms a {
  color: inherit;
  text-decoration: none;
  transition: color 250ms;
}

.TitleIconList_list__3OXms a:hover {
  color: #80683f;
}

.TitleIconList_list__3OXms > li {
  align-items: center;
  display: inline-flex;
  margin: 0 0 0.5em 2.667em;
}
.TitleIconList_list__3OXms > li:first-child {
  margin-left: 0;
}

.OrderProductForm_buttons__20Wgj {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.OrderProductForm_colRight__1-sNl {
  position: relative;
}

.OrderProductForm_fieldTitle__3cflS {
  color: #000;
  font-family: 'brandon-grotesque';
  font-size: 0.625em;
  font-weight: normal;
  letter-spacing: 0.15em;
  line-height: 1.6;
  text-transform: uppercase;
}

.OrderProductForm_field__3kSFw {
  margin-bottom: 16px;
}
.OrderProductForm_form__zSIJy {
  width: 100%;
}
.OrderProductForm_form__zSIJy .OrderProductForm_fieldTitle__3cflS {
  margin: 0 0 0.5em;
  font-size: 0.563em;
}
.OrderProductForm_form__zSIJy label {
  font-size: 1em;
}

.OrderProductForm_leftCol__23l9O,
.OrderProductForm_marginBottom__27Ig9 {
  margin-bottom: 0.25em;
}

.OrderProductForm_statusValue__1-m9y {
  background: #fff;
  color: #000;
  padding: 0 1.2em;
}

@media screen and (min-width: 992px) {
  .OrderProductForm_leftCol__23l9O {
    margin-bottom: 0;
  }
}
.OrderProductForm_imagesRow__3Q6lX h3 {
  color: #000;
  font-family: 'brandon-grotesque';
  font-size: 0.813em;
  font-weight: normal;
  letter-spacing: 0.05em;
  line-height: 1.6;
  text-transform: uppercase;
}
.OrderProductForm_orderShippingDetailsRow__3w8n9 {
  margin-bottom: 24px;
}

.OrderProductForm_imageList__2f6NX img {
  max-width: 120px;
  height: auto;
}
.OrderProductForm_imageListItem__3fH42 {
  max-width: calc(120px + 30px);
}
.OrderProductForm_imageInputWithList__23l-B {
  display: flex;
}

/* Dirty hack to increase specificity to bypass bootstrap's ridiculously specific styles */
.OrderProductForm_proformaButton__2d0-_.OrderProductForm_proformaButton__2d0-_ {
  font-size: 0.575rem;
  margin-top: 8px;
  width: 50%;
}

.OrderProductForm_proformaFile__3k0ii {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.OrderProductForm_proformaFile__3k0ii .btn-group {
  width: 100%;
}

.OrderProductForm_carbCert__1wYYm {
  margin-right: 18px;
}
.OrderProductForm_packages__27q-w {
  margin-top: 3em;
  padding-left: 15px;
}
.OrderProductForm_packages__27q-w label {
  font-size: 0.625em;
}

.OrderProductForm_fileInput__2F2GE {
  margin-top: 14px;
}
.OrderProductForm_fileInputsTitleA__Yetat {
  text-decoration: underline;
  cursor: pointer;
}

.OrderProduct_label__X6Bya,
.OrderProduct_quantity__3xzVm {
  color: #000;
  font-size: 0.75em;
  letter-spacing: 0.15em;
  line-height: 1.6;
  text-transform: uppercase;
}

.OrderProduct_label__X6Bya {
  margin: 0 0 1.5em;
}

.OrderProduct_product__3OE9Q {
  margin: 0 0 0.5em;
}

.OrderProduct_quantity__3xzVm {
  margin-top: 16px;
}

.OrderProduct_text__bGoQp {
  color: #919191;
  font-size: 0.875em;
  line-height: 1.286;
}

.OrderProduct_wrap__dk5qS {
  background: #f1f2f2;
  font-family: 'brandon-grotesque';
  padding: 15px 0;
}
.OrderProduct_details__2SnRU {
  margin-top: 0.5em;
}
.OrderProduct_details__2SnRU p {
  display: flex;
  align-items: center;
  font-size: 0.875em;
}
.OrderProduct_details__2SnRU svg {
  margin-right: 0.525em;
}

.OrderProduct_header__1OPGY {
  color: #000;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.OrderProduct_customization__3EvuB {
  display: flex;
  flex-direction: column;
}

.OrderProduct_fulfilmentLinks__3nMh_ li {
  display: flex;
  margin-left: 0;
}

.OtherProducts_productList__21d5H {
  margin-left: -15px;
  margin-right: -15px;
}

.OtherProducts_wrap__2VVgl h2 {
  margin: 0 0 2.5em;
}

.PageNav_item__3ww0X {
  color: #2d2d2d;
  display: block;
  padding: 0.24em 1em;
  transition: all 0.4s ease;
  text-decoration: none;
}

a.PageNav_item__3ww0X {
  cursor: pointer;
}

.PageNav_item__3ww0X:hover,
.PageNav_current__34ebl .PageNav_item__3ww0X {
  background-color: var(--main-07-color);
  color: #2d2d2d;
}

.PageNavVendorActions_actionsTop__3ClLS {
  display: flex;
  justify-content: flex-end;
}

.PageTop_onboarding__3eEQ2 {
  margin-bottom: 1.5em;
}

.PlanStatus_wrap__3DljT {
  border: 1px solid #3e9653;
  border-radius: 0.75em;
  color: #3e9653;
  padding: 0.25em 0.5em;
  text-transform: uppercase;
}

.ProductCard_card__187sV {
  font-family: 'brandon-grotesque';
  text-transform: uppercase;
  width: 120px;
}
.ProductCard_card__187sV p {
  color: #000;
  font-size: 0.75em;
  line-height: 1.333;
}
.ProductCard_card__187sV .ProductCard_status__Lfv81 {
  font-size: 0.625em;
  line-height: 1;
}

.ProductCard_img__3Ieho {
  height: 120px;
  overflow: hidden;
  margin: 0;
}
.ProductCard_img__3Ieho > img {
  max-height: 100%;
  max-width: 100%;
}

.ProductCard_name__1gdZZ {
  color: #000;
  font-weight: bold;
  letter-spacing: 0.128em;
  margin: 0.5em 0 0;
}

.ProductCard_price__34xye {
  letter-spacing: 0.15em;
  margin: 0.333em 0 0;
}

.ProductCard_status__Lfv81 {
  font-weight: bold;
  letter-spacing: 0.15em;
  margin: 1.2em 0 0;
}

.ProductSummary_bottomString__U7mkv {
  bottom: 0;
  color: #000;
  font-size: 0.75em;
  font-weight: bold;
  left: 0;
  letter-spacing: 0.15em;
  line-height: 1.333;
  margin: 0;
  position: absolute;
}

.ProductSummary_cnt__2KCAG {
  position: relative;
}

.ProductSummary_image__U88y_ {
  display: inline-block;
  margin: 0 0.75em 0 0;
  overflow: hidden;
}
.ProductSummary_image__U88y_ img {
  max-height: 100%;
  max-width: 100%;
}

.ProductSummary_mod1__zbFxl .ProductSummary_cnt__2KCAG {
  padding: 0 0 1em;
}
.ProductSummary_mod1__zbFxl .ProductSummary_image__U88y_ {
  margin-right: 0.625rem;
}

.ProductSummary_name__1FQm5 {
  color: #000;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.128em;
  line-height: 1.333;
  margin: 0;
  text-transform: uppercase;
}

.ProductSummary_id__39AA4 {
  font-size: 0.75em;
  margin: 0;
  text-transform: uppercase;
}

.ProductSummary_wrap__3ZO3Q {
  align-items: center;
  display: flex;
}
.ProductSummary_wrap__3ZO3Q.ProductSummary_mod1__zbFxl {
  align-items: initial;
}

.Status_wrap__12ujf,
.Status_wrap__12ujf a {
  color: var(--main-bg-color);
}

.Tooltip_wrap__2sBTn {
  display: inline-flex;
  font-size: 1rem;
}

.Tooltip_wrap__2sBTn span {
  display: inline-flex;
  align-items: center;
}

.TranslationProduct_img__213pj {
  max-width: 100%;
}

.TranslationProduct_wrap__15c6U .TranslationProduct_otherImages__3GNqr {
  margin: -0.7em 0.5em 0;
}

.VendorActions_ddItem__S5_O5 {
  cursor: pointer;
  text-decoration: none;
}

.VendorActions_wrap__AlHFT {
  align-items: center;
  display: flex;
}

.VendorActions_wrap__AlHFT button {
  font-size: 0.75rem !important;
}

.VendorActions_updateDates__EXo-a {
  font-size: 0.75rem;
}

.VendorActions_updateDates__EXo-a > div {
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #f1f2f2;
}

.VendorActions_updateDates__EXo-a > div:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.VendorActions_lastChanges__35Y2E {
  font-size: 0.75rem;
  max-height: calc(40vh);
  overflow-y: auto;
}

.VendorActions_lastChanges__35Y2E > div {
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.VendorActions_lastChanges__35Y2E > div:last-child {
  border-bottom: 0;
}

.VendorActions_lastChanges__35Y2E > div > div {
  border-bottom: 1px solid #f1f2f2;
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
}

.popover {
  border-radius: 0px;
  font-family: 'brandon-grotesque';
}

.VendorActions_popoverInner__391nJ h3 {
  background-color: #f1f2f2;
  font-size: 0.875rem;
}

.popover .arrow::after {
  border-bottom-color: #f1f2f2 !important;
}

.BraintreePlansList_button__3Pt1p {
  margin: 0.5em 0 0;
}
.BraintreePlansList_button__3Pt1p input[type='radio'] {
  position: static;
}

.BraintreePlansList_buttonPending__1holT {
  margin-top: 0.5;
}

.BraintreePlansList_gIconOk__Hb2Na {
  color: #0f0;
  display: inline-block;
  font-size: 2em;
  margin: -0.3em 0 0 0.5em;
}

.BraintreePlansList_planStatus__1tIBk {
  color: #f00;
  font-size: 0.75em;
  text-transform: uppercase;
}

.BraintreePlansList_planStatusLbl__MyseJ {
  margin: 0 0 0 0.25em;
  position: relative;
}
.BraintreePlansList_planStatusLblMsg__3KXp7 {
  background: #fff;
  border: 1px solid #000;
  border-radius: 0.25em;
  font-size: 0.75em;
  padding: 0.1em 0.25em 0;
  position: absolute;
  text-transform: uppercase;
  white-space: nowrap;
}

.BraintreePlansList_price__A04C0 {
  font-size: 0.85em;
}

.BraintreePlansList_wrap__1gOh5 h1 {
  margin: 0;
}
.BraintreePlansList_wrap__1gOh5 p {
  margin: 0.5em 0 0;
}

.FloatList_list__2wk4z {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.FloatList_list__2wk4z > li {
  margin: 0 0 1em;
}
.FloatList_list__2wk4z > li.FloatList_active__df-xe {
  font-weight: 700;
}
.FloatList_list__2wk4z p {
  width: 100%;
}

.FloatList_list__2wk4z.FloatList_noWrap__4x0Pw {
  flex-wrap: nowrap;
}

.FloatList_mod1__2YQKS {
  color: #000;
  text-transform: uppercase;
}
.FloatList_mod1__2YQKS a {
  color: #000;
  text-decoration: none;
}
.FloatList_mod1__2YQKS a:hover {
  text-decoration: underline;
}

.ImagesList_item__3Pxfe {
  margin-bottom: 1.5em;
  text-align: center;
}
.ImagesList_imageSquare__2pQKq {
  height: 120px;
  width: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.ImagesList_imageSquare__2pQKq span {
  color: transparent;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.ItemsVisibleList_itemsRest__WJW_3 {
  align-items: center;
  bottom: 1.333em;
  display: flex;
  font-size: 0.75em;
  letter-spacing: 0.125em;
  position: absolute;
  right: 0;
  top: 0;
  width: 4em;
}

.ItemsVisibleList_wrap___faAf {
  color: #9d9e9e;
  padding: 0 3.5em 0 0;
  position: relative;
}
.ItemsVisibleList_wrap___faAf.ItemsVisibleList_noVisibleItems__1B2SP {
  padding-right: 0;
}

.LabelValueList_list__24tc5 {
  font-family: 'brandon-grotesque';
  margin-left: -15px;
  margin-right: -15px;
}
.LabelValueList_list__24tc5 li {
  font-size: 1em;
  margin-bottom: 2em;
}
.LabelValueList_list__24tc5 p {
  color: #000;
  display: block;
  font-size: 0.875em;
  letter-spacing: 0.129em;
  line-height: 1.714;
  margin: 0.444em 0;
  width: 100%;
}

.LabelValueList_list__24tc5 .LabelValueList_mod1__1t830 p {
  font-size: 1.125em;
  font-weight: bold;
  line-height: 1.333;
  letter-spacing: 0.15em;
}

.LabelValueList_list__24tc5 .LabelValueList_label__4QMmb,
.LabelValueList_list__24tc5 .LabelValueList_mod1__1t830 .LabelValueList_label__4QMmb {
  color: #9d9e9e;
  font-size: 0.625em;
  font-weight: normal;
  line-height: 1.6;
  margin: 0;
  text-transform: uppercase;
}

.OtherImagesList_image__2tABv {
  background: #fff;
  border: 1px dashed #000;
  display: flex;
  height: 5.5em;
  justify-content: center;
  padding: 0.5em;
  width: 5.5em;
}
.OtherImagesList_image__2tABv > img {
  display: block;
  max-height: 100%;
  max-width: 100%;
}

.ProductHeadingList_content__PC7Z- {
  align-items: center;
  display: flex;
  font-size: 0.75em;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 0.12em;
  line-height: 1.3;
  margin: 0 0 0 0.75em;
  text-transform: uppercase;
}

.ProductHeadingList_data__39ffN {
  align-items: center;
}

.ProductHeadingList_hits__TfIuB {
  color: #000;
  display: flex;
  font-weight: normal;
  margin-left: 2px;
}
.ProductHeadingList_hitsIcon__2Cbxy {
  color: #9d9e9e;
  display: flex;
  margin: 0 0.5em 0 0;
  width: 1em;
}

.ProductHeadingList_image__3mf_o {
  background: #fff;
  height: 3em;
  line-height: 3em;
  width: 3em;
}
.ProductHeadingList_image__3mf_o a {
  display: inline-block;
  height: 100%;
  width: 100%;
}
.ProductHeadingList_image__3mf_o img {
  max-height: 100%;
  max-width: 100%;
}

.ProductHeadingList_item__1Nqs1 {
  margin-bottom: 1.5em;
}
.ProductHeadingList_item__1Nqs1 a {
  text-decoration: none;
}

.TitleIconContent_cnt__rBqJ2 {
  align-items: center;
  display: flex;
  margin: 0.75em 0 0;
}

.TitleIconContent_content__xibIq {
  color: #000;
  font-family: 'BentonModDisp SB';
  font-size: 2.25em;
  line-height: 1;
  margin: 0 0 0 0.44em;
  width: 100%;
}

.TitleIconContent_icon__PzVDt {
  align-items: center;
  color: #e6e7e8;
  display: flex;
}

.TitleIconContent_list__v5V8G > li {
  margin-bottom: 1.5em;
}
.TitleIconContent_list__v5V8G > li p {
  display: block;
}

.TitleIconContent_mod1__-PshI {
  text-transform: uppercase;
}

.TitleIconContent_mod1__-PshI .TitleIconContent_content__xibIq {
  font-family: 'brandon-grotesque', sans-serif;
  font-size: 1.5em;
  letter-spacing: 0.15em;
  line-height: 1;
  margin-left: 0.667em;
}

.TitleIconContent_title__xCJyx {
  color: #9d9e9e;
  font-size: 0.625em;
  letter-spacing: 0.129em;
  line-height: 1.6em;
  text-transform: uppercase;
  width: auto;
}

.BraintreePlanTable_renewing__dLr_W {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.BraintreePlanTable_status__3KFKj {
  font-weight: 600;
}

.BraintreePlanTable_table__gCiSP {
  text-transform: uppercase;
  width: auto;
}
.BraintreePlanTable_table__gCiSP td {
  vertical-align: middle;
}

.OrderDetails_table__Dpgqr {
  border: 0 none;
  font-family: 'brandon-grotesque';
  text-transform: uppercase;
}

.OrderDetails_table__Dpgqr th,
.OrderDetails_table__Dpgqr tbody tr,
.OrderDetails_table__Dpgqr thead tr {
  background: none;
}
.OrderDetails_table__Dpgqr td,
.OrderDetails_table__Dpgqr thead th {
  border: 0 none;
}

.OrderDetails_table__Dpgqr tbody tr:hover td {
  background: #f1f2f2;
}

.OrderDetails_table__Dpgqr td {
  color: #9d9e9e;
  font-size: 0.75em;
  letter-spacing: 0.128em;
  vertical-align: middle;
}

.OrderDetails_table__Dpgqr thead th {
  color: #9d9e9e;
  font-size: 0.625em;
  font-weight: normal;
  letter-spacing: 0.129em;
  line-height: 1.6;
  text-transform: uppercase;
  vertical-align: middle;
}

.OrderProductsTable_table__F36Hb {
  font-family: 'brandon-grotesque';
  margin: 0;
  overflow: hidden;
}

.OrderProductsTable_table__F36Hb,
.OrderProductsTable_table__F36Hb td,
.OrderProductsTable_table__F36Hb thead th {
  border: 0 none;
  vertical-align: middle;
}

.OrderProductsTable_table__F36Hb th,
.OrderProductsTable_table__F36Hb > tbody > tr,
.OrderProductsTable_table__F36Hb thead tr {
  background: none;
}

.OrderProductsTable_table__F36Hb th {
  color: #9d9e9e;
  font-size: 0.625em;
  font-weight: normal;
  letter-spacing: 0.129em;
  line-height: 1.6;
  text-transform: uppercase;
}

.OrdersTable_productImg__2q2F5 {
  display: inline-block;
  height: 5em;
  width: 5em;
  overflow: hidden;
}
.OrdersTable_productImg__2q2F5 > img {
  max-height: 100%;
  max-width: 100%;
}

.OrdersTable_table__1RR-c {
  border: 0 none;
  font-family: 'brandon-grotesque';
}

.OrdersTable_table__1RR-c td,
.OrdersTable_table__1RR-c thead th {
  border: 0 none;
  vertical-align: middle;
}

.OrdersTable_table__1RR-c td {
  color: #9d9e9e;
  font-size: 0.75em;
  letter-spacing: 0.128em;
  line-height: 1.33;
}

.OrdersTable_table__1RR-c td.OrdersTable_orderNumber__1pjQX {
  color: #000;
  font-size: 0.875em;
  font-weight: 900;
  letter-spacing: 0.129em;
  line-height: 1.57;
}
.OrdersTable_table__1RR-c td.OrdersTable_orderNumber__1pjQX > a {
  text-decoration: none;
}

.OrdersTable_table__1RR-c td.OrdersTable_pending__2nctT {
  color: #000;
}
.OrdersTable_table__1RR-c td .OrdersTable_price__KCJQb {
  color: #000;
  font-weight: 600;
}
.OrdersTable_table__1RR-c td.OrdersTable_status__1YTKW {
  font-size: 0.625em;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.6;
  text-transform: uppercase;
}
.OrdersTable_table__1RR-c td.OrdersTable_working__yq-94 {
  color: #b79961;
}

.OrdersTable_table__1RR-c th,
.OrdersTable_table__1RR-c tr:nth-child(odd) {
  background-color: transparent;
}
.OrdersTable_table__1RR-c thead th {
  color: #9d9e9e;
  font-size: 0.625em;
  font-weight: normal;
  letter-spacing: 0.129em;
  line-height: 1.6;
  text-transform: uppercase;
  width: auto !important;
}

.Table_mod1__3RTP3 {
  color: #7d7d7f;
  font-size: 0.75em;
}
.Table_mod1__3RTP3 td {
  border-color: #f1f1f1;
}

.Table_rightCell__nFV6j {
  text-align: right;
}

.Table_mod1__3RTP3 > .Table_thead__2KvId th {
  background: #fafafa;
  border: 0 none;
  color: #7e7d83;
}

.SalesTable_table__3_Cpo {
  font-size: 1em;
}

.SalesTable_table__3_Cpo tbody tr {
  background: #fff;
}

.SalesTable_table__3_Cpo td,
.SalesTable_table__3_Cpo th {
  vertical-align: middle;
  font-size: 0.875rem;
}

.SalesTable_table__3_Cpo img {
  max-height: 100px;
}

.SalesTable_table__3_Cpo a {
  word-wrap: none;
}

.SalesTable_tooltip__cC2Oc {
  margin-left: 4px;
  margin-top: 6px;
  display: inline;
}

.SalesTable_exportBtn__2M9vp {
  margin-bottom: 10px;
}

.SalesTable_product__1gv8P {
  display: flex;
  align-items: center;
}

.SalesTable_product__1gv8P img {
  margin-right: 10px;
}

.SalesTable_productLinkWrapper__A7EMR {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.BraintreeSubscriptionPage_cta__2yqXQ {
  margin: 2em 0 0;
  text-align: right;
}

.BraintreeSubscriptionPage_description__2BA4f p {
  margin: 0.5em 0 0;
  max-width: 700px;
}

.BraintreeSubscriptionPage_dropIn__3qc15 {
  margin: 1em 0 0;
}

.BraintreeSubscriptionPage_page__15yfm {
  margin: 0.625em 0 0;
  text-align: left;
  margin: 0 auto;
}

.BraintreeSubscriptionPage_plansList__337j6 {
  margin: 2em 0 1em;
}
.BraintreeSubscriptionPage_plansList__337j6 h1 {
  font-size: 2em;
}
.BraintreeSubscriptionPage_plansList__337j6 > li {
  width: calc(100% - 1em);
}

.BraintreeSubscriptionPage_unsubscribe__1ThaP {
  margin: 0 0 0 1em;
}

@media screen and (min-width: 600px) {
  .BraintreeSubscriptionPage_plansList__337j6 > li {
    width: calc(50% - 1em);
  }
}

.PDFModal_flex__28bgW {
  display: flex;
  align-items: center;
}

.PDFModal_pages__11bzw {
  margin-right: 1rem;
}

.react-pdf__Page__textContent {
  transform: none !important;
  top: 0 !important;
  left: 0 !important;
}

.PasswordSetForm_btn__3LVLp {
  margin: 1.75em 0 0;
  text-align: center;
}

.PasswordSetForm_input__286OB {
  margin: 0 0 2.5em;
}
.PasswordSetForm_input__286OB input {
  background: #fff;
  border-color: #ced4da;
  font-size: 1em;
  height: 2.375em;
  padding: 0.375em 0.75em;
}
.PasswordSetForm_input__286OB label {
  color: #afafaf;
}

.DropdownToggle_dropdownToggle__2M3R4 {
  padding: 0.625rem 0.75rem;
  border-left: 1px solid #b79961;
}
.DropdownToggle_dropdownToggle__2M3R4[aria-expanded='true'] {
  background: #b79961 !important;
  border-color: #b79961 !important;
}
.DropdownToggle_dropdownToggle__2M3R4:hover {
  background: #b79961;
  border-color: #b79961;
}

.DropdownToggle_black__19Brz {
  background-color: #000;
  color: #fff;
  font-weight: 600;
}

.ProductDetailForm_buttons__1QyO6 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 1em;
}
.ProductDetailForm_buttons__1QyO6 > button {
  width: 48%;
}

.ProductDetailForm_colRight__3HeP_ {
  padding-top: 1.7em;
}
.ProductDetailForm_noMargin__M0464 {
  margin: 0 -15px;
}
.ProductDetailForm_daysInput__srAdU {
  padding-bottom: 1.14em !important;
  padding-top: 1.14em !important;
  width: 5em !important;
}

.ProductDetailForm_leadTimeInput__1GxOC {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.ProductDetailForm_leadTimeInput__1GxOC label {
  margin-right: 0.5em;
  margin-bottom: 0;
  text-transform: none;
  font-size: 0.8125em;
}

.ProductDetailForm_description__2eUda {
  min-height: 11em;
}

.ProductDetailForm_field__3FZR5 {
  margin: 0 0 2em;
}

.ProductDetailForm_fileInputs__1Jnal {
  margin: 2em 0 0;
}

.ProductDetailForm_form__3C9Go h2 {
  margin-bottom: 1.5em;
}

.ProductDetailForm_onboardingImage__203HN {
  margin: 0 0 1em;
}

.ProductDetailForm_popup__1mewO {
  cursor: pointer;
}

.ProductDetailForm_row__2Sex-,
.ProductDetailForm_row1_1__Yga8F {
  padding-bottom: 2em;
  padding-top: 2em;
  position: relative;
}
.ProductDetailForm_row__2Sex-:before,
.ProductDetailForm_row1_1__Yga8F:before {
  border-top: 1px solid #d8d8d8;
  content: '';
  left: 15px;
  position: absolute;
  right: 15px;
  top: 0;
}

.ProductDetailForm_row1__2TtR9 {
  padding-bottom: 2em;
}
.ProductDetailForm_row1_1__Yga8F {
  padding-bottom: 0;
}

.ProductDetailForm_rowMargins__2oHRv {
  margin-left: -15px;
  margin-right: -15px;
}

.ProductDetailForm_packagingTime__3aLp7 {
  width: 10em;
}

.ProductDetailForm_suitableForContract__1RpCn {
  margin-bottom: 1.5em;
}
.ProductDetailForm_suitableForContract__1RpCn label {
  margin-bottom: 0.2em;
}

.ProductDetailForm_verticalCenter__2_iA3 {
  align-items: center;
  display: flex;
  margin-bottom: 1em;
}
.ProductDetailForm_saveAdmin__5EU9H {
  width: 100%;
}
.ProductDetailForm_saveAdminWrapper__1C5VN {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .ProductDetailForm_colRight__3HeP_ {
    padding-top: 0;
  }
}

.ProductDetailForm_parcelsError__1IdaO {
  display: block;
  margin-top: 1em;
  font-size: 100%;
}

.ProductDetailForm_tooltip__2zwaW {
  display: inline-flex;
  margin-left: 0.3em;
  margin-bottom: 1px;
}

.SearchForm_SearchForm__FR_5t {
  padding: 0 18px 0 0;
  position: relative;
}

.SearchForm_SearchForm__Button__zIIaa {
  background: url(/static/media/search.4fc4f9ad.svg) no-repeat 50% 50%;
  border: 0 none;
  bottom: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
}

.SearchForm_SearchForm__Field__2XwCh {
  background: none;
  border: 0 none;
  color: #9d9e9e;
  height: 100%;
  width: 11em;
}
.SearchForm_SearchForm__Field__2XwCh:focus {
  background: none;
  box-shadow: none;
}
.SearchForm_SearchForm__Field__2XwCh > div {
  height: 100%;
  margin: 0;
}
.SearchForm_SearchForm__Field__2XwCh > div > label {
  display: none;
}

.SearchFormDropdown_content__3dcXX {
  display: flex;
  width: 16em;
}

.SearchFormDropdown_contentText__1XC-e {
  margin: 0 0 0 0.5em;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

.SearchFormDropdown_image__27RIH {
  height: 4em;
  width: 4em;
}

.SearchFormDropdown_item__2zyiS {
  cursor: pointer;
}

.SearchFormDropdown_menu__1a83U {
  left: auto !important;
  right: 0 !important;
  top: 3.3em !important;
  transform: unset !important;
}

.TranslateProductForm_buttons__2cdfs {
  margin: 1em 0 0;
}
.TranslateProductForm_buttons__2cdfs > button {
  margin: 0 0.5em 0.5em 0;
}

.TranslateProductForm_input__iNq-d {
  margin-bottom: 1em;
}

.TranslateProductForm_label__2Wcdh {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 0 0.5em;
}

.UpdateCredentialsForm_buttons__2Bg71 {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin: 1em 0 0;
}
.UpdateCredentialsForm_buttons__2Bg71 > button:first-child {
  margin-right: 0.5em;
}

.UpdateCredentialsForm_fieldsList__p-Cx5 > div {
  margin-bottom: 1em;
}

.InnerPage_headingSlot__2cvDP {
  display: flex;
  align-items: flex-end;
}

.InnerPage_pageTitle__3Dj0e {
  display: flex;
  align-items: center;
}

.InnerPage_pageTitle__3Dj0e svg {
  margin-left: 0.625rem;
  height: 1.7rem;
  width: 1.7rem;
}

.InnerPage_artisanStatus_draft__1fADN,
.InnerPage_artisanStatus_active__1WF3z,
.InnerPage_artisanStatus_inactive__1vVqQ,
.InnerPage_artisanPlan__1himL {
  margin: 0 10px;
  font-size: 16px;
  font-family: 'brandon-grotesque';
  font-weight: bold;
  letter-spacing: normal;
  text-transform: uppercase;
}

.InnerPage_artisanStatus_draft__1fADN {
  color: var(--main-golden);
}

.InnerPage_artisanStatus_active__1WF3z {
  color: var(--main-green-color);
}

.InnerPage_artisanStatus_inactive__1vVqQ {
  color: var(--main-darkRed-color);
}

.InnerPage_artisanAction__2woIj {
  margin-left: auto;
  white-space: nowrap;
  line-height: 1;
}

.InnerPage_publishErrors__3w9eJ {
  padding-left: 1rem;
  padding-top: 0.625rem;
}

.InnerPage_publishErrors__3w9eJ li {
  /* text-transform: capitalize; */
  list-style: disc;
  margin-bottom: 3px;
}

.InnerPage_artisanHeading__1mHiy {
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.InnerPage_artisanHeading__1mHiy h1 {
  display: inline;
}

.InnerPage_artisanHeading__1mHiy svg {
  margin-bottom: 5px;
  flex-shrink: 0;
}

.InnerPage_artisanPlan__1himL {
  white-space: nowrap;
}

.approver-dashboard-component_actionButton__3Moxn {
  display: block;
  font-size: 0.7em;
  padding: 0.5em 1em;
}

.approver-dashboard-component_actionButton1__1uql6 {
  margin: 0 0 1em;
}

.approver-dashboard-component_actionCell__18XD7 {
  display: flex;
  justify-content: flex-end;
}

.approver-dashboard-component_col1__2GaAl {
  width: 80px !important;
}

.approver-dashboard-component_notFound__3dq53 {
  margin: 1em 0;
}

.approver-dashboard-component_paginator__2Htud {
  margin: 2em 0 0;
}

.approver-dashboard-component_tableTitle__2H6pf {
  font-weight: bold;
  font-size: x-large;
}

.approver-dashboard-component_tdName__u-Pbn,
.approver-dashboard-component_tr__2uh5F {
  cursor: pointer;
}

.approver-dashboard-component_artisanName__1E6qS {
  display: flex;
  align-items: center;
}
.approver-dashboard-component_artisanName__1E6qS span {
  margin-left: 8px;
}

@media screen and (min-width: 700px) and (max-width: 991px) {
  .approver-dashboard-component_actionButton1__1uql6 {
    margin: 0 1em 0 0;
  }

  .approver-dashboard-component_actionButtonWrap__161dr {
    display: flex;
  }
}

@media screen and (min-width: 800px) and (max-width: 991px) {
  .approver-dashboard-component_actionButton__3Moxn {
    font-size: 0.75em;
    padding: 0.84em 1.66em;
  }
}

@media screen and (min-width: 1250px) {
  .approver-dashboard-component_actionButton1__1uql6 {
    margin: 0 1em 0 0;
  }

  .approver-dashboard-component_actionButtonWrap__161dr {
    display: flex;
  }
}

.VendorAnalyticsLayout_pageNav__kw6v- {
  margin-left: -1em;
}

.VendorAnalyticsLayout_topMenuCnt__2DSPx {
  padding: 1px 0 0;
  position: relative;
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 2em;
  justify-content: space-between;
  align-items: center;
}

.VendorAnalyticsLayout_topMenu__27zyj {
  background: #f0f0f0;
  padding: 5px;
  border-radius: 12px;
  font-size: 0.875em;
}
.VendorAnalyticsLayout_topMenu__27zyj > li {
  margin: 0;
  padding: 5px 16px;
  border-radius: 12px;
}

.VendorAnalyticsLayout_topMenu__27zyj > li a:hover {
  text-decoration: none;
  font-weight: bold;
}

.VendorAnalyticsLayout_active__3PY0K {
  background-color: white;
}


@media screen and (max-width: 670px) {
  .VendorAnalyticsLayout_topMenu__27zyj {
    margin-top: 1em;
    position: static;
  }
}

.CollectionTranslations_customProps__1nQT- > li {
  margin: 0 0 0 1em;
}

.CollectionTranslations_info__2Lq-t {
  margin: 0 0 2em 1em;
}
.CollectionTranslations_info__2Lq-t > li {
  margin: 0 0 0.25em;
}

.CollectionTranslations_infoValue__3Z8Rn {
  margin: 0 0 0 2em;
}

.CollectionTranslations_page__26lWd .CollectionTranslations_pageMenu__1QdY2 {
  margin-bottom: 1em;
  margin-left: -30px;
}

.CollectionTranslations_product__k_fCI {
  margin-bottom: 3em;
}

.MassUpdater_buttons__20_fZ {
  margin: 1em 0 0;
}

.MassUpdater_control__jiCQ0 {
  margin: 0.8em 0 0;
}

.MassUpdater_input__2Tb_N {
  margin: 1em 0 0;
}

.MassUpdater_info__YjLtr {
  margin: 1em 0 0;
}

.MassUpdater_results__1m74w {
  margin-top: 1em;
  max-height: 500px;
  overflow-y: auto;
}

.MassUpdater_results__1m74w > div {
  padding: 5px;
}

.MassUpdater_results__1m74w > div {
  border-bottom: 1px solid #666;
}

.MassUpdater_results__1m74w > div.MassUpdater_summary__3PmxZ {
  padding-bottom: 1em;
  position: sticky;
  top: 0;
  background-color: var(--main-bg-light);
}

.MassUpdater_results__1m74w > div.MassUpdater_summary__3PmxZ > span {
  margin-right: 1em;
}

.MassUpdater_results__1m74w > div.MassUpdater_success__1dQGJ {
  background-color: #35b533;
}

.MassUpdater_results__1m74w > div.MassUpdater_partially__3cs4J {
  background-color: #ffff8f;
}

.MassUpdater_results__1m74w > div.MassUpdater_error__2xazq {
  background-color: #ff9f9f;
}

.copy-modal_listContainer__1imTD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.copy-modal_listItem__cTrjH {
  padding: 10px;
}

.ProductDetail_children__1oY87 {
  padding-left: 0;
  padding-right: 0;
}

.ProductDetail_fileInputsTitle__2GYIW {
  display: block;
  width: auto;
}

.ProductDetail_fileInputsTitleA__2mOO0 {
  font-weight: normal;
  margin: 0 0 0 0.5em;
}

.ProductDetail_form__2EhjG {
  margin: 1em 0 0;
}

.ProductDetail_otherImages__1lbyX {
  margin: 0 -15px;
}
.ProductDetail_otherImagesWrap__EmTlQ > div {
  margin-bottom: 2em;
}

.ProductDetail_otherProducts__2bXBL {
  margin: 2.5em 0 0;
}

.ProductDetail_page__15rFo {
  padding: 0 0 3em;
}

.ProductDetail_page__15rFo .ProductDetail_actionsTop__1NSZM {
  margin-right: 0;
}

.ProductDetail_border__1uaEZ {
  border: 0.9375rem solid;
  position: fixed;
  left: 0;
  width: 15px;
  height: 100vh;
  top: 0;
}
@media screen and (max-width: 768px) {
  .ProductDetail_border__1uaEZ {
    border: 0.3125rem solid;
    width: 0.3125rem;
  }
}

.BarChart_barIcon__31cTQ,
.BarChart_labelFormatterIcon__2zlwI {
  color: #9d9e9e;
  height: 1em;
  margin: 0 0.5em 0 0;
}

.BarChart_barValue__2nFza,
.BarChart_labelFormatter__3-ghZ {
  align-items: center;
  display: flex;
}

.BarChart_toolTip__3Toxa {
  align-items: center;
  display: flex;
}

.BarChart_toolTipColor__ZG-Ts {
  border: 1px solid #fff;
  display: inline-block;
  height: 1em;
  margin: 0 0.5em 0 0;
  width: 1em;
}

.PieChart_toolTip__38tcH {
  align-items: center;
  display: flex;
}
.PieChart_toolTipColor__ukyaj {
  border: 1px solid #fff;
  display: inline-block;
  height: 1em;
  margin: 0 0.5em 0 0;
  width: 1em;
}

.VendorAnalytics_diagramWrap__1Gde0 {
  width: 100%;
}

.VendorAnalytics_h2__3ZViQ {
  margin: 3em 0 0;
}
.VendorAnalytics_wrap__UlO-5 .VendorAnalytics_h2__3ZViQ {
  font-size: 0.8125em;
  letter-spacing: 0.15em;
}

.VendorAnalytics_topProducts__241c1 {
  margin: 4.5em -15px 0;
}

.VendorAnalytics_topProductsHd__2DYIy {
  display: flex;
  align-items: center;
  margin: 0 0 3.25em;
}

.VendorAnalytics_wrap__UlO-5 .VendorAnalytics_topProductsHd__2DYIy {
  font-size: 0.875em;
}

.VendorAnalytics_topProductsHd__2DYIy  {
  display: flex;
  align-items: center;
}

.VendorAnalytics_topProductsHd__2DYIy h2 {
  margin: 0 0.5em 0 0;
}

.VendorAnalytics_topProductsHd__2DYIy a {
  margin-left: 1em;
}

.VendorAnalytics_instructions__3Y0cz {
  margin-bottom: 3em !important;
}

.VendorAnalytics_views__25K7P {
  align-items: center;
  display: flex;
  margin: 2.625em 0 0;
}
.VendorAnalytics_views__25K7P .VendorAnalytics_viewsProductViews__1jqLS {
  margin-right: 1em;
  min-width: auto;
}
.VendorAnalytics_viewsProductViews__1jqLS > li {
  margin-bottom: 0;
}

@media screen and (max-width: 575px) {
  .VendorAnalytics_topProductsHd__2DYIy {
    margin-bottom: 1.5em;
  }

  .VendorAnalytics_topProductsList__RSdK0 {
    margin: 0 -15px 1.5em;
  }
}

@media screen and (max-width: 600px) {
  .VendorAnalytics_views__25K7P {
    display: block;
  }

  .VendorAnalytics_viewsProductViews__1jqLS {
    margin-bottom: 2em;
  }
}

.VendorAnalyticsAudience_hd__2VWt3 {
  font-size: 1.2em;
  margin: 0 0 0.5em !important;
  text-align: center;
}

.VendorAnalyticsAudience_intro__1N5GG {
  margin-bottom: 3em !important;
}

.VendorAnalyticsAudience_layout__2bGDb > div {
  margin-bottom: 2em;
}

@media screen and (min-width: 767px) {
  .VendorAnalyticsAudience_hd__2VWt3 {
    font-size: 1.5em;
  }
}

.VendorAnalyticsSales_status__1QMWv {
  margin: 1em 0 0;
}
.VendorDashboard_buttonPropose__hByhG {
  margin: 2em 0 0;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
}

.VendorDashboard_col__3yU7z {
  padding-bottom: 1.5em;
  padding-top: 4.5em;
}

.VendorDashboard_notFound__3J1oV {
  font-family: 'brandon-grotesque';
}

.VendorDashboard_ordersTable__3hn9R {
  margin-top: 1.875em;
}

.VendorDashboard_page__68a1s {
  padding-bottom: 2em;
}
.VendorDashboard_page__68a1s .VendorDashboard_main__2pK3b h2 {
  font-size: 0.8125em;
}

.VendorDashboard_paginator__3-oMz {
  margin: 2em 0 0;
}

.VendorDashboard_productsToDescribe__3wMyl {
  margin: 2.625em -3px 0;
}

.VendorDashboard_sidebar__2krCX {
  background: #f1f2f2;
}

.VendorDashboard_totalCnt__fNUZ1 {
  margin: 0 -15px 3em;
}

@media screen and (max-width: 768px) {
  .VendorDashboard_sidebar__2krCX {
    padding-top: 2em;
  }
}

@media screen and (min-width: 992px) {
  .VendorDashboard_sidebarCnt__2-OLq {
    padding-left: 3.5em;
  }
}

.Radio_label__vNaFe::after {
  top: 0;
}
.Radio_label__vNaFe {
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
}

.VendorOrderShipment_container__38z3q {
  background: #f1f2f2;
  font-family: 'brandon-grotesque';
  padding: 1.5rem;
}
.VendorOrderShipment_submitRow__1Kqa4 {
  display: flex;
  justify-content: flex-end;
}
.VendorOrderShipment_fileInputsTitleA__2bqHu {
  font-weight: normal;
  display: block;
}
.VendorOrderShipment_fileInputTitle__1scqO {
  margin-bottom: 0.5rem;
}

.VendorOrderShipment_rate__35RzD {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.VendorOrderShipment_rateName__32hMT {
  font-size: 0.875rem;
  margin-top: -0.1875rem;
}

.VendorOrderShipment_ratePrice__3BXtK {
  font-size: 0.875rem;
}

.VendorOrderShipment_rateDescription__1O7Hz {
  font-size: 0.625rem;
}

.VendorOrderShipment_rateError__3cLs6 {
  background-color: var(--main-pink-color);
  border: 1px solid var(--main-red-color);
  color: white;
  margin: 5px 0;
  padding: 5px;
}

.VendorOrderShipment_fileInputs__3OsDI {
  display: flex;
  grid-gap: 4rem;
  gap: 4rem;
}

.VendorOrderShipment_checkbox__2cEuj {
  margin-top: 1.5rem;
}

.VendorOrderShipment_textInput__1MJTc {
  width: calc(100% / 4);
  margin-bottom: 1.5rem;
  display: block;
}

.VendorOrderShipment_textInput__1MJTc:not(:first-child) {
  margin-top: 1.5rem;
}

.VendorOrderShipment_textInput__1MJTc input {
  background-color: #fff;
}

.VendorOrderShipment_shipErrors__35dnK {
  margin-top: 3.5rem;
}

.Part_bold__1iXBs {
  font-weight: bold;
}

.Part_buttonCancel__2LxN9 {
  margin: 0 2.857em 0 0;
}

.Part_buttons__2cyRo {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 3.4375em 0 0;
}

.Part_children__ZD7Ro {
  margin: 3.25em 0 0;
}

.Part_status__1Vho0 {
  margin-bottom: 1.5em;
}

@media screen and (max-width: 500px) {
  .Part_buttons__2cyRo {
    display: block;
  }

  .Part_buttonsGroup__H5HAh {
    margin: 1em 0 0;
  }
}

.vendor-contact_error__2fk49 {
  color: #f00;
  font-size: 1.3em;
  line-height: 0.25;
  margin: 0 0 0 0.1em;
}

.vendor-contact_main__1k80y {
  margin: 0 auto;
  text-align: left;
  color: var(--main-text);
}

.vendor-contact_pageNav__126fv {
  margin: 0 -15px 0.5em;
}

.vendor-contact_titleRow__XQrMb {
  font-size: 24px;
  font-weight: bold;
  height: 60px;
  line-height: 1.2;
}

.vendor-contact_artemestPickupRow__1abo- {
  margin-bottom: 2em;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-checkbox .custom-control-label::after {
  top: 0;
}

.custom-checkbox
    .custom-control-input:checked
    ~ div
    .custom-control-label::after {
  background-position: 50%;
}

.custom-checkbox
    .custom-control-input:checked
    ~ div
    .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.PickupForm_submitButtonWrapper__2NVJu {
  display: flex;
  justify-content: flex-end;
}

.PickupForm_pickupLocations__lV9X- {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  color: #555;
}

.VendorOrderDetail_form__31EAm input,
.VendorOrderDetail_form__31EAm textarea,
.VendorOrderDetail_formInputFile__363gu {
  background: #fff;
}

td.VendorOrderDetail_detailTd__3ADZ8 {
  padding: 0;
  position: relative;
}

.VendorOrderDetail_orderProduct__1WzwH {
  margin: 0 0 1.5em;
}

.VendorOrderDetail_packageDimensions__2ht0D,
.VendorOrderDetail_shippingDate__3G2Wy {
  color: #9d9e9e;
  font-size: 0.75em;
  letter-spacing: 0.128em;
}

.VendorOrderDetail_packagedReadyImages__1dlaj {
  display: flex;
  margin: 0;
}
.VendorOrderDetail_packagedReadyImages__1dlaj > li {
  border: 1px solid #f1f2f2;
  height: 48px;
  margin: 0 0 0 1em;
  overflow: hidden;
  width: 48px;
}
.VendorOrderDetail_packagedReadyImages__1dlaj > li:first-child {
  margin-left: 0;
}
.VendorOrderDetail_packagedReadyImages__1dlaj > li img {
  max-height: 100%;
  max-width: 100%;
}

.VendorOrderDetail_part__160Ha {
  padding: 4.5em 0 0;
}

.VendorOrderDetail_price__3qrpU,
.VendorOrderDetail_status__17U50 {
  color: #000;
  font-size: 0.625em;
  font-weight: bold;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.VendorOrderDetail_price__3qrpU {
  font-size: 0.75em;
}

.VendorOrderDetail_togglerOn__27CyU {
  position: absolute;
  right: 1em;
  top: 1em;
}

.VendorOrderDetail_togglerTd__2NXCf {
  text-align: right;
}

.VendorOrderDetail_topList__3P1X4 {
  left: calc(15px + 15em);
  position: absolute;
  right: 15px;
  text-align: right;
  top: -25.5px;
}

.RowToggle_togglerLink__nGpdS {
  text-decoration: none;
}
.RowToggle_togglerLink__nGpdS:hover {
  text-decoration: underline;
}

td.VendorOrders_number__3ALxZ {
  color: #000;
  font-size: 0.875em;
  font-weight: bold;
}
td.VendorOrders_price__3r3MO {
  color: #000;
  font-weight: bold;
  letter-spacing: 0.15em;
}
td.VendorOrders_status__17LM4 {
  color: #000;
  font-size: 0.625em;
  font-weight: bold;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
td.VendorOrders_toggler__3vPPM {
  font-size: 1em;
}

td.VendorOrders_rowToggle__MMyfC {
  background: #f1f2f2;
  font-size: 1em;
  position: relative;
}

.VendorOrders_page__2jD2P {
  position: relative;
}

.VendorOrders_pageNav__3pevW {
  margin: 0 0 0 -1em;
}

.VendorOrders_status__17LM4 {
  padding: 0.6em 1.2em;
}
.VendorOrders_ordersTable__20nJZ tr:hover td .VendorOrders_status__17LM4 {
  background: #fff;
}

.VendorOrders_ordersTable__20nJZ {
  transition: opacity 250ms;
}

.VendorOrders_ordersTableLoading__FjDK8 {
  opacity: 10%;
}

.VendorOrders_table__3PvZK {
  padding: 1.25rem 0 0;
}

.VendorOrders_tip__3nMkK {
  left: calc(15px + 15em);
  position: absolute;
  right: 15px;
  text-align: right;
  top: -25.5px;
}

.VendorOrders_toggled__1VUBC {
  position: absolute;
  right: 1em;
  top: 1.3em;
}

.VendorOrders_emptyState__3K8ci {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.VendorOrders_emptyStateHidden__33KrL {
  opacity: 0;
}

.VendorSubscription_status__2ElZH {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.VendorSubscription_detail__95B7H {
  margin-bottom: 10px;
}

.VendorSubscription_statusActive__VBmkG {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--main-green-color);
}

.VendorSubscription_statusInactive__3WzA5 {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--main-darkRed-color);
}

.VendorSubscription_pageNav__2p6Sp {
  margin: 0 0 0.5em -1em;
}

.VendorSubscription_button__3C4sC {
  margin-top: 20px;
  display: block;
  outline: none;
}

@media all and (min-width: 480px) {
  .login_Login__1QY8w {
    padding: 60px 0;
  }

  .login_Login__1QY8w form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.login_body__3cRio {
  position: absolute;
  background: #fff;
  width: 100%;
  background: linear-gradient(
      to right bottom,
      var(--main-03-color),
      var(--main-03-color)
    ),
    url(/static/media/art.6da29121.jpeg) 0 0 / cover no-repeat;
  min-height: 100%;
  padding-bottom: 100px;
}

.login_mainDiv__3zvwA {
  text-align: left;
  background: #fff;
  padding: 30px;
  margin-top: 5%;
}

.login_mainRow__2TEqS {
  margin: 0;
}

.login_loginButtonDiv__1RtMK {
  margin: 15px auto 0;
  text-align: center;
  width: 100%;
}

.login_loginButton__2nTS2 {
  padding: 10px 20%;
  margin-top: 5%;
}

.login_privacyPolicy__N2AAG {
  margin: 0.25em 0 0;
  text-align: center;
}

.login_txtColor__178vS {
  color: #afafaf;
}

.login_title__3F0_V {
  color: #afafaf;
  text-align: center;
  margin-bottom: 40px;
}

.login_logo__3RBbR {
  margin: 5% 0 5% 25%;
}

.login_error__3SjAq {
  width: 100%;
  background-color: var(--main-darkRed-color);
  padding: 20px 20px;
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
}

.login_div__1IcSq {
  z-index: 2;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  min-height: 110px;
  background: linear-gradient(rgba(183, 153, 97, 0), rgba(183, 54, 51, 0.2));
}

@keyframes login_heartBeat__k8AM1 {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.1);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.1);
  }

  70% {
    transform: scale(1);
  }
}

.login_heartBeat__k8AM1 {
  animation-name: login_heartBeat__k8AM1;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out;
}

@keyframes login_flipInX__1lswm {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.login_flipInX__1lswm {
  backface-visibility: visible !important;
  animation-name: login_flipInX__1lswm;
  animation-duration: 0.7s;
}

.login_copyRight__2D9wM {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
}

.login_copyRightP__2zhsz {
  color: var(--loading-brown-color);
  text-shadow: 2px 2px var(--main-04-color);
}

.login_resetPasswordForm__-UMVw {
  margin: 1em 0 0;
}

.SapOrders_pageNav__2h2Ai {
  margin: 0 0 0.5em -1em;
}

.SapOrders_orderLink__3YUZE {
  display: block;
}

.SapOrders_input__2HSEo {
  display: inline-block;
  padding: 6px 8px;
  border: 1px solid var(--main-bg-color) !important;
  box-sizing: border-box;
  color: black;
  height: 36px;
  background-color: white;
  font-size: inherit;
  width: 200px;
}

.SapOrders_button__RtsbD {
  margin: 0 15px;
  margin-top: -1px;
  width: 175px;
}

.SapOrders_success__300fj,
.SapOrders_error__1L5dU {
  margin-top: 5px;
}

.SapOrders_success__300fj {
  color: green;
}

.SapOrders_error__1L5dU {
  color: red;
}

.header_header__v9FKw {
  margin: 0 auto;
  padding: 5px 0;
}

.header_logo__1X7aW {
  height: 50px;
  max-width: 150px;
}
.header_logoLink__1C9w5 {
  margin: 0 1em 0 0;
}

.header_avatar__1yr3k {
  margin-right: 2.5%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition-delay: 1s;
  transition-duration: 1s;
}

.header_dropdown__2x9Iy {
  margin-right: 3%;
  outline: none;
  border: none;
  background: transparent;
}
.header_dropdownMenu__2WAkw {
  min-width: 12rem;
}
.header_dropdown__2x9Iy .header_btn-secondary__cc73a {
  box-shadow: none;
}

.header_dropbtn__d3d26 {
  background-color: transparent;
  border-radius: 50%;
  padding: 0;
  font-size: 16px;
  border: none;
  transition-delay: 1s;
  transition-duration: 1s;
}

.header_dropdown__2x9Iy {
  position: relative;
  display: inline-block;
}

.header_dropdown-content__qWr0Z {
  transform: translateX(-50%);
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.header_out__3iodk {
  content: '\e163';
  width: 30px;
}

.header_dropdown-content__qWr0Z a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.header_p__2VLja {
  cursor: pointer;
  color: black;
  display: block;
  margin: 5px;
  padding: 12px 16px;
  text-decoration: none;
  width: auto;
}

.header_dropdown-content__qWr0Z a:hover {
  background-color: var(--main-bg-color);
}

.header_p__2VLja:hover {
  background-color: var(--main-bg-color);
  color: #fff;
  text-decoration: none;
}

.header_dropdown__2x9Iy:hover .header_dropdown-content__qWr0Z {
  display: block;
}

.header_search__130F1 {
  align-items: center;
  display: flex;
  margin: 0 1em 0 0;
}

.header_smallLogo__3F-NF {
  border: none;
  height: 40px;
  outline: none;
  width: 40px;
}

@media screen and (max-width: 990px) {
  .header_dropdown__2x9Iy {
    margin-right: 0;
  }

  .header_dropdown-content__qWr0Z {
    transform: translateX(-70%);
  }
}

.header_link__3CRBA {
  display: flex;
  font-size: 0.875em;
  font-weight: bold;
  height: 56px;
  justify-content: center;
  margin: 0 20px 0 0;
  text-decoration: none;
  text-transform: uppercase;
}

.header_link-a__2jjPl {
  align-items: center;
  display: flex;
  font-weight: 900;
  letter-spacing: 0.13em;
  position: relative;
}
.header_link-active__28LpH {
  color: var(--main-bg-color);
}
.header_link-a__2jjPl:after {
  content: '';
  position: absolute;
  height: 3px;
  width: 100%;
  left: 0;
  top: -13px;
  visibility: hidden;
  background-color: var(--main-bg-color);
  -webkit-transition: all 0.1s ease;
  color: var(--main-bg-color);
  transition: all 0.3s ease;
  transform: scaleX(0);
  text-decoration: none;
}
.header_link-a__2jjPl:hover:after,
.header_link-active__28LpH:after {
  transform: scaleX(1);
  text-decoration: none;
  visibility: visible;
}

.header_nav-item__3LGbo {
  margin: auto !important;
}

.header_link__3CRBA a {
  text-decoration: none;
  transition: 0.3s;
}

.header_modalButton__2ADmb {
  margin: 0 5px;
}

.header_flagButton__3ngeR {
  border: 0;
  background: transparent;
  margin-left: 0.75rem;
}

.header_navbar__1S2KZ {
  justify-content: space-between;
}

.header_navbar-lang__1HKvr {
  margin: 0 0 5px;
}

@media screen and (max-width: 768px) {
  .header_link__3CRBA {
    margin: 0;
  }

  .header_link-a__2jjPl {
    padding: 0.5em 0;
  }
  .header_link-a__2jjPl:after {
    top: 0;
  }

  .header_search__130F1 {
    justify-content: center;
    margin-right: 0;
  }
}

.loading_mainDiv__2_noP {
  position: fixed;
  background-color: var(--loading-color);
  margin: 0;
  padding: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1051;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_progressStatus__13ADh,
.loading_progressBarWrapper__2tLen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.loading_progressStatus__13ADh {
  top: 0;
  line-height: 2;
}

.loading_progressBarWrapper__2tLen {
  height: 2rem;
  width: 75%;
  max-width: 600px;
  border: 1px solid var(--main-golden);
  border-radius: 5px;
  overflow: hidden;
}

.loading_progressBar__1ffvK,
.loading_progressBarColor__xf3aT {
  height: 100%;
  width: 100%;
  background-color: var(--main-bg-light);
}

.loading_progressBarColor__xf3aT {
  background-color: var(--main-golden);
}

.footer_mainDiv__10MzA {
  margin-top: 5px;
  background: #222222;
  padding: 20px 0 20px 0;
  border-top: var(--main-bg-color) solid 3px;
  position: relative;
  width: 100%;
}

.footer_allRights__1OFB5 {
  display: block;
  text-align: center;
  color: #bcbcbc;
}

.footer_absolute__2JlBy {
  position: absolute;
  bottom: 0;
}

.footer_absoluteDiv__cnQWJ {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
}

.footer_unShownDiv__1Rrcy {
  z-index: -1;
  width: 0;
  background-color: var(--main-darkRed-color);
  position: relative;
  top: 0;
  height: 100vh;
}

.admin_link__1kKEX {
  min-width: 235px !important;
}

.admin_ButtonsList__1qTPz {
  border-bottom: var(--main-bg-color) solid 0.5px;
  padding: 15px 0;
}

.admin_mainDiv__DGoJz {
  margin-top: 20px;
}

.collection-card_alignLeft__21z1n {
  position: absolute;
  left: 0;
}

.collection-card_alignRight__Juo1B {
  position: absolute;
  right: 0;
}

.collection-card_SKU__2X36l {
  background-color: var(--main-04-color);
  padding: 0;
  text-align: center;
  font-size: x-large;
  height: 45px;
  font-weight: bold;
}

.collection-card_submit__1vdgv {
  background-color: var(--main-lightGreen-color);
  border: 1px solid var(--main-green-color);
  color: white;
  padding: 12px 20px;
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

.collection-card_warning__3uxSB {
  background-color: var(--warning);
  border: 1px solid var(--warning);
  color: black;
  padding: 12px 20px;
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

.collection-card_mainCol__3qYlv {
  margin-bottom: 15px;
}

.collection-card_danger__-xxAh {
  background-color: var(--main-pink-color);
  border: 1px solid var(--main-red-color);
  color: white;
  padding: 12px 20px;
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

submit:hover {
  background-color: var(--main-lightGreen-color);
}

.collection-card_danger__-xxAh:hover {
  background-color: var(--main-red-color);
}

@media screen and (max-width: 450px) {
  .collection-card_danger__-xxAh {
    width: 100%;
    display: block;
    position: relative;
  }

  .collection-card_submit__1vdgv {
    width: 100%;
    display: block;
    position: relative;
  }
}

.collection-card_body__1jACb {
  padding: 0;
}

.collection-card_footer__1grgK {
  position: relative;
  height: 45px;
  background-color: transparent;
  border: none;
}

.collection-card_footer__1grgK button {
  top: 0;
}

.approval-detail_main__21g9S {
  width: 96%;
  margin: 0 auto;
  text-align: left;
}

.approval-detail_header__2HVJb {
  margin-top: 10px;
}

.approval-detail_actions__29wsP {
  text-align: right;
}

.approval-detail_cardRow__1yd-M {
  margin-top: 20px;
}

.approval-detail_actions__29wsP button {
  margin-right: 2.5px;
}

.approval-detail_home__2gUtE {
  margin-left: 10px;
  display: inline-block;
  font-size: 24px;
}

.approval-detail_h2__1X06m {
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-family: 'bentonmod';
  color: var(--main-bg-color);
  margin-top: 3%;
}

.approver-dashboard_currentPage__1Ddqm {
  background-color: var(--main-bg-color);
  color: white;
  font-weight: bold;
}

.approver-dashboard_paginationLinks__1GLRx {
  text-decoration: none;
}

.approver-dashboard_paginationLinks__1GLRx:disabled {
  background-color: var(--main-disabled-color);
}

.approver-dashboard_pagination__3Y_UA {
  position: absolute;
  right: 15px;
}

.translator-table-card_card__3CMZ3 {
  margin: 0.5em 0 0;
  text-align: left;
}

.translator-table-card_img__3McjR {
  height: 24px;
  margin: 0 0.5em 0 0;
  width: 24px;
}

.translator-table-card_values__Lsxbk {
  font-size: 1.2em;
  margin: 0;
}

.translator-table-card_bio__1RHMm {
  margin: 0;
}

.translator-table-card_title__QXuje {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.translator_customProps__3Kg0c {
  padding: 0 0 0 1em;
}

.translator_openTranslations__VSrFJ {
  font-size: 24px;
  font-weight: bold;
  padding: 15px 0;
  text-align: left;
  display: block;
}

.translator_otherImages__2ejOp {
  margin: -0.6em 0.5em 0;
}

.translator_paddingBottom___1cY6 {
  padding-bottom: 40px;
}

.translator_loadingWrapper__2Hmq2 {
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
}

.translator_pageNav__2chPP {
  margin: 0 0 1em -15px;
}

.translator_vendorBioForProdTranslations__OxdJS {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  display: block;
}

.translator_managerText__OUJxK {
  display: block;
  width: 100%;
}

.translator_managerTextWarning__3J5YG {
  color: var(--main-red-color);
}

.translator_materialAndColor__3GOuY {
  text-align: left;
  font-weight: bold;
  margin-top: 15px;
}

.translator_materialAndColor__3GOuY div {
  margin: 5px 0;
}

.translator_productIdProdTranslations__ei2Vk {
  font-size: 24px;
  font-weight: bold;
  padding: 5px 0;
  text-align: left;
  display: block;
}

.translator_text__1Ek5N {
  text-align: left;
}

.translator_textArea__u_xxu textarea {
  height: 27vh !important;
}

.translator_buttons__3XhWl {
  margin: 15px;
}

.translator_card__14-uk {
  display: block;
  margin: 2vh 0 0;
}

.translator_cardCompBio__2OZ_i {
  display: block;
  margin-bottom: 2vh;
}

.translator_errorText__3L90m {
  color: var(--main-red-color);
}

.translator_paginator__38NA9 {
  margin-top: 2em;
}

.translator_productImage__3miir {
  width: 100%;
}

.translator_h2__3ooIC {
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-family: 'bentonmod';
  color: var(--main-bg-color);
  margin-top: 3%;
}

.translator_radioLeft__6Fhp5 {
  text-align: left;
}

.translator_p__2XgYR {
  display: inline-block;
  margin: 0 10px 0 0;
}

.export-preview-component_mainImage__2af7e {
  width: 100%;
}

.export-preview-component_materialDiv__3_znq {
  margin: 10px 0;
}

.export-preview-component_designerDiv__2dEdH p {
  display: inline-block;
}

.export-preview-component_designerDiv__2dEdH {
  margin: 10px 0;
}

.export-preview-component_main__nMBfH {
  text-align: left;
}

.export-preview-component_imgDiv__3fshS {
  height: max-content;
  border: dashed 1px var(--main-border-color);
  padding: 0.625em;
}

.export-preview-component_currencies__3ztqu {
  margin: 10px 0;
  padding: 0;
}

.export-preview-component_otherImages__1PVbj {
  margin: -1em 0 0 1em;
}

p {
  margin-bottom: 0 !important;
}

.export-preview-component_parcels__3koyD {
  margin: 1em 0 0;
}

.export-preview-component_prodName__3XLyk {
  color: #f00;
}

.export-preview-component_sizing__11I1- {
  margin: 10px 0;
}

.export-preview-component_heights__2L3jT {
  margin: 10px 0;
}

.export-preview-component_heights__2L3jT h4 {
  display: inline-block;
  margin-left: 10px;
}

.export-preview-component_code__SKgun {
  overflow: hidden;
}

.export-preview_firstRow__1bWV6 {
  margin: 10px -15px;
  position: relative;
}
.export-preview_firstRow__1bWV6 button {
  bottom: 1em;
  position: absolute;
  right: 15px;
}

.export-preview_nav__2-GwI {
  margin: 0 -15px 1.5em;
}

.export-preview_paginationDiv__FHMJL {
  margin-bottom: 40px;
}

.export-preview_pagination__xAsQx {
  position: absolute;
  right: 50px;
}

.export-preview_currentPage__1vRf- {
  background-color: var(--main-bg-color);
  color: white;
  font-weight: bold;
}

.export-preview_paginationLinks__1Z6q1 {
  padding: 10px 0;
  text-decoration: none;
}

.export-preview_paginationLinks__1Z6q1 a {
  color: black;
  padding: 10px 15px;
  text-decoration: none;
}

.export-preview_paginationLinks__1Z6q1 span {
  padding: 10px 15px;
  text-decoration: none;
}

.export-preview_paginationLinks__1Z6q1:disabled {
  background-color: var(--main-disabled-color);
}

.image-assets-component_button__3i7ND {
  display: flex;
  justify-content: flex-end;
}

.image-assets-component_col-sm-5__1WfEY,
.image-assets-component_col-sm-2__Cu0xp {
  margin-left: 30px;
}

.image-assets-component_imageClose__1fhS7 {
  border: dashed 1px #8f8f8f;
  padding: 5px;
}

.image-assets-component_inputFile__1b642 {
  margin-bottom: 1em;
}

.image-assets-component_label__vcFfi {
  color: var(--main-bg-color);
}
.image-assets-component_labelSuccess__zhcRI {
  color: var(--main-success-color);
}

.image-assets-component_mainRow__2PF9m {
  margin-top: 30px;
}

.image-assets-component_otherImages__1A4Ik {
  margin: -1em 0 0 1em;
  position: relative;
  z-index: 1;
}

.image-assets-component_uploadBtn__k5C5v {
  height: 40px;
}

.image-assets-component_onboardingImage__2K7zW {
  width: 100%;
}

.image-assets-component_onImageCol__3b7Nl {
  height: max-content;
  margin: 0 0 1em;
  padding: 0 15px;
}

@media screen and (max-width: 576px) {
  .image-assets-component_button__3i7ND {
    justify-content: flex-start;
  }
}

.image-assets_hd__1gNmm {
  margin-top: 0.5em;
}

.image-assets_prods__3soC- {
  margin-top: 20px;
}

.merchandising_main__3_IKU {
  text-align: left;
  width: 96%;
  margin: 0 auto;
}

.merchandising_selectEl__2n7G6 {
  width: 60%;
}

.merchandising_headerRow__3gnlZ {
  padding: 10px 0;
}

.merchandising_buttonEl__2z3hP {
  position: absolute;
  right: 20px;
}

.merchandising_selectAll__3PEDp {
  margin: 0.5em 0;
}

.merchandising_data__1wZYr {
  padding-left: 39px;
}

.merchandising_discounts__2DTVj {
  margin: 0.3em 0 0;
}

.merchandising_image__3gNMp {
  width: 100%;
  padding: 10px;
  border: 1px var(--main-border-color) dashed;
}
.merchandising_imageWrap__UYD7B {
  margin: 0 0 1em;
  padding: 0 0 0 24px;
  position: relative;
}
.merchandising_imageWrap__UYD7B .merchandising_checkbox__1I0_v {
  left: 0;
  position: absolute;
  top: 0;
}

.merchandising_mainRow__1H8Ji {
  margin-top: 30px;
}

@media all and (min-width: 768px) {
  .merchandising_data__1wZYr {
    padding-left: 15px;
  }
}

.merchandising_daysInput__1voGX {
  padding-bottom: 1.14em !important;
  padding-top: 1.14em !important;
  width: 5em !important;
}

.merchandising_leadTimeInput__3MeSJ {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.merchandising_leadTimeInput__3MeSJ span {
  margin-bottom: 0;
  text-transform: none;
}

.merchandising_leadTimeInput__3MeSJ input {
  margin: 0 0.5em;
}

.merchandising_leadTimeInput__3MeSJ input[aria-invalid='true'] {
  padding-right: 20px !important;
}
.merchandising_data__1wZYr button,
.merchandising_leadTimeInput__3MeSJ button {
  width: 10em;
}

.merchandising_leadTimeInput__3MeSJ button {
  margin-left: 1em;
}

.merchandising_disabled__3_9Kp {
  pointer-events: none;
  opacity: 0.5;
}

.merchandising_error__X5D8g {
  width: 100%;
  color: #dc3545;
  margin-bottom: 10px;
  margin-top: -10px;
}

.mainDiv {
  position: relative;
  width: 94%;
  left: 3%;
  right: 3%;
}

.leftDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 49%;
}

.rightDiv {
  position: absolute;
  right: 0;
  top: 0;
  width: 49%;
}

.pageTitle {
  margin-bottom: 10px;
}

.pageTitle,
.pageTitle h1 {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
}
.pageTitle h1 {
  font-family: 'BentonModDisp SB', serif;
  font-size: 1.285em;
  letter-spacing: 0.03em;
  margin: 0;
}

.pageTitle1 {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  margin: 15px 0 0 25px;
  display: block;
  padding: 0;
  color: var(--main-bg-color);
}

.container {
  margin-left: 40%;
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: var(--main-blue-color);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

@media screen and (max-width: 750px) {
  .rightDiv {
    position: relative;
    display: block;
    width: 100%;
  }

  .leftDiv {
    position: relative;
    display: block;
    width: 100%;
    min-height: 360px;
  }
}

.onboarding_additionalFields__1iadJ {
  padding-top: 3em;
  padding-bottom: 1em;
  position: relative;
}

.onboarding_additionalFields__1iadJ:before {
  border-top: 1px solid #d8d8d8;
  content: '';
  left: 15px;
  position: absolute;
  right: 15px;
  top: 0;
}

.onboarding_ddCountryRegion__3pfrR {
  background: #fff;
  border-color: var(--main-bg-color);
}

.onboarding_input__uOy30[type='text'],
.onboarding_inputWrap__3dyzZ input,
.onboarding_select__3HZWj,
.onboarding_input__uOy30[type='email'],
.onboarding_input__uOy30[type='number'],
.onboarding_textarea__3Y4XW,
.onboarding_multipleSelect__2VDR4 select {
  display: inline-block;
  padding: 12px 16px;
  border: 1px solid var(--main-bg-color) !important;
  box-sizing: border-box;
  color: black;
  min-height: 50px;
  background-color: white;
  font-size: inherit;
}

.onboarding_input__uOy30[type='text'],
.onboarding_input__uOy30,
.onboarding_select__3HZWj,
.onboarding_input__uOy30[type='email'],
.onboarding_input__uOy30[type='number'],
.onboarding_textarea__3Y4XW,
.onboarding_multipleSelect__2VDR4,
.onboarding_radioWrapper__26krn {
  width: 60%;
}

.onboarding_multipleSelect__2VDR4 select option {
  margin-bottom: 3px;
}

.onboarding_select__3HZWj {
  padding-left: 14px;
}

.onboarding_input__uOy30::placeholder,
.onboarding_inputWrap__3dyzZ input::placeholder {
  color: #ccc;
  letter-spacing: normal;
}

.onboarding_button__1oQmD {
  width: 40%;
  height: 50px;
}
.onboarding_button__1oQmD[disabled] {
  cursor: auto;
}

.onboarding_multipleSelect__2VDR4.onboarding_fixedPriceList__3nRza > select {
  height: 5.4em;
}

.onboarding_form__3qYK5 {
  margin-bottom: 1em;
  margin-top: 4em;
}

.onboarding_input__uOy30[type='submit'] {
  width: 40%;
  color: #fff;
  padding: 14px 20px;
  margin: 15px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.onboarding_input__uOy30[type='submit']:hover {
  background-color: var(--main-04-color);
}

.onboarding_inputWrap__3dyzZ {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 2em;
  flex-wrap: wrap;
}
.onboarding_inputWrap__3dyzZ.onboarding_alignRight__1Llii {
  justify-content: flex-end;
}

.onboarding_uploadWrap__3Udzn {
  margin-bottom: 2em;
}

.onboarding_uploadWrap__3Udzn ul {
  margin-bottom: 1em;
}

.onboarding_uploadWrap__3Udzn li {
  display: flex;
  align-items: center;
}

.onboarding_uploadWrap__3Udzn a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.onboarding_uploadWrap__3Udzn button {
  background-color: transparent;
  border: 0;
}

.onboarding_label__3WH_W {
  margin: 0;
  text-align: right;
  width: 35%;
}

.onboarding_textarea__3Y4XW {
  /* height: 150px; */
  border-color: var(--main-bg-color);
  /* padding: 20px; */
  box-sizing: border-box;
}

.onboarding_input__uOy30:focus,
.onboarding_textarea__3Y4XW:focus {
  background-color: var(--main-04-color);
}

.onboarding_checkboxs__18wkW {
  text-align: left;
  width: 60%;
  display: inline-block;
}

.onboarding_sameLineCheckboxs__2e1H9 {
  display: flex;
}

.onboarding_sameLineCheckboxs__2e1H9 > div:not(:first-child) {
  margin-left: 20px;
}

.onboarding_check__1sOfw {
  width: 100%;
}

.onboarding_check__1sOfw p {
  vertical-align: top;
  width: 40%;
  display: inline-block;
  margin: 0;
}

.onboarding_areaInput__1TAyW {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.onboarding_error__1xzvM {
  color: red;
  padding: 0;
  text-transform: uppercase;
  width: 60%;
  font-size: 0.6875em;
  font-weight: 500;
  margin-top: 5px;
  /* text-align: right; */
  margin-left: auto;
}

.onboarding_wrap__1CwiH select,
.onboarding_wrap__1CwiH .onboarding_input__uOy30,
.onboarding_wrap__1CwiH .onboarding_textarea__3Y4XW,
.onboarding_wrap__1CwiH .custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.onboarding_wrap__1CwiH .custom-checkbox .custom-control-label::before,
.onboarding_wrap__1CwiH .custom-radio .custom-control-label::before {
  border-color: var(--main-bg-color);
}

.onboarding_wrap__1CwiH
  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after,
.onboarding_wrap__1CwiH
  .custom-radio
    .custom-control-input:checked
    ~ .custom-control-label::after {
  background-position: 50% 0;
}

.onboarding_date__djV4c {
  font-size: 0.75em;
  text-transform: uppercase;
  letter-spacing: 0.1125em;
}

.onboarding_date__djV4c .onboarding_dateLabel__1vD4z {
  font-weight: 500;
}

.onboarding_date__djV4c:not(:last-child) {
  margin-bottom: 1em;
}

.onboarding_date__djV4c:last-child {
  margin-bottom: 2em;
}

.style_btn__5yndE {
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-top: 0.4em solid #585b5e;
  cursor: pointer;
  height: 0.4em;
  margin: -0.2em 0 0;
  position: absolute;
  right: 0.4em;
  top: 50%;
  width: 0.4em;
}

.style_input__wNzTk {
  position: relative;
}

.style_inputField__q3ufk {
  padding-right: 1.5em;
}

.css-UI3_fileInput__2PJWF {
  margin: 1em 0;
}

.css-UI3_mainDiv__nHbjT {
  text-align: left;
}

.css-UI3_title__5xYzY {
  font-size: 18px;
  font-weight: bold;
  margin: 10px -15px;
}

.css-UI3_productCol__3VgAn {
  border: dashed 1px var(--main-border-color);
  margin: 5px 0;
  padding: 15px 5px;
}

.css-UI3_prodImage__3ftIT {
  width: 100%;
  min-height: 80%;
}

.css-UI3_actionsCol__2tXA6 button {
  margin: 10px 0;
}

.css-UI3_leftTitle__2STi6 {
  text-align: left;
}

.css-UI3_rightTitle__UvWOd {
  text-align: right;
}

.page-now-found_mainTitle__A_1Zl {
  font-size: 3.33333333rem;
  font-family: 'bentonmod';
  color: #000000;
}

.page-now-found_p__15jR1 {
  font-family: 'brandon-grotesque';
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  color: #2d2d2d;
  display: inline-block;
}

.page-now-found_a__2N9FX {
  text-decoration: none;
  color: var(--main-bg-color);
}

.page-now-found_textContainer__2Y_ry {
  margin-top: 5%;
}

.page-now-found_h2__33QTa {
  font-size: 4rem;
  font-family: 'bentonmod';
  color: var(--main-bg-color);
  margin-top: 3%;
}

.page-now-found_footerDiv__-BDdl {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: max-content;
}

.pricing-table_image__36AwZ {
  max-height: 2em;
  max-width: 2em;
}

.pricing-table_input__1I2kn {
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: var(--file-input-bg-color);
  width: 6.5rem;
}

tr.pricing-table_rowColName__LV4FK {
  background: var(--main-bg-color);
}

.pricing-table_rowColName__LV4FK td {
  text-align: center;
}

.pricing-table_select__2D7x8 {
  margin-bottom: 5px;
}

.pricing-table_sku__C8sWm {
  align-items: center;
  display: flex;
}
.pricing-table_sku__C8sWm .pricing-table_image__36AwZ {
  margin: 0 0 0 1em;
}

.pricing-table_twoValues__1k9H5 {
  margin-top: 12px;
  width: 100%;
}

.pricing-table_types__RbT2b .dropdown-menu {
  min-width: 25em;
}
.pricing-table_types__RbT2b .dropdown-menu > li {
  font-size: 0.75em;
}

.pricing-table_verticalMiddle__ino4H {
  vertical-align: middle !important;
}

.pricing_colNameLink__yZ8UZ,
.pricing_colNameLink__yZ8UZ:hover {
  color: #212529;
}
.pricing_colNameLink__yZ8UZ:hover {
  text-decoration: none;
}

.pricing_main__12MOv {
  width: 96%;
  margin: 0 auto;
  text-align: left;
}

.pricing_main__12MOv h4 {
  display: inline;
  margin: 20px 0;
  font-weight: bold;
}

.pricing_header__2F4Yp {
  position: relative;
  padding: 10px 0;
}

.add-product-data-types_btnDeleteField__3SAk9 {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.add-product-data-types_inputElement__2pdPL {
  display: inline-block;
  margin-top: 0.5em;
  vertical-align: top;
}
.add-product-data-types_inputElement__2pdPL:first-child {
  margin-top: 0;
}

.add-product-data-types_col-sm-5__2tLyO {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
}

.add-product-data-types_col-sm-2__XeHs3 {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 4px;
}

.add-product-data-types_col-sm-12__K9RxF {
  margin-top: 5px;
  margin-bottom: 5px;
}

.add-product-data-types_list-group-item__N53Aq {
  padding: 3px 1.25rem;
  border: none;
}

li p {
  width: 42%;
  padding: 0;
  display: inline-block;
}

.add-product-data-types_rowAdd__3gQml {
  margin: 1em 0 1em -15px;
}
.add-product-data-types_rowAdd__3gQml > div {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.add-product-data-types_vendorInfo__qvOzB {
  display: flex;
  justify-content: space-between;
}

.add-product-data-types_vendorInfoKey__2WMeJ {
  display: inline-block;
  margin: 0;
  font-weight: bold;
  vertical-align: top;
  width: 60%;
}

.add-product-data-types_vendorInfoValue__3VQAU {
  align-items: center;
  display: flex;
  padding: 0;
  margin: 0;
}
.add-product-data-types_vendorInfo__qvOzB .add-product-data-types_vendorInfoValue__3VQAU {
  width: auto;
}

.add-product-data-types_col-sm-5__2tLyO:first-child {
  padding-left: 15px;
}

.add-product-data-types_myRow__1tM70 {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.add-product-data-types_updateInputDiv__10o87 {
  width: 40%;
  display: inline-block;
}

.add-product-data-types_idInputDiv__2wh56 {
  display: inline-block;
  vertical-align: top;
  width: 60%;
}

.add-product-data-types_idInput__mqO7c {
  display: inline-block;
  margin: 0 0 0.5em;
  vertical-align: top;
  width: 100%;
  border-color: var(--main-bg-color);
}

.add-product-data-types_list-group-item__N53Aq {
  margin-top: 10px;
}

.add-product-data-types_label__1OYwG {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.add-product-data-types_labelFile__2NC-P {
  margin-top: 12px;
}

.add-product-data-types_rowAddField__sXWqh {
  display: flex;
  padding-left: 0;
  padding-right: 0;
}
.add-product-data-types_rowAddField__sXWqh > div {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.add-product-data-types_rowAddField__sXWqh .add-product-data-types_vendorInfoKey__2WMeJ {
  margin-top: 0.5em;
  width: 100%;
}
.add-product-data-types_rowAddField__sXWqh .add-product-data-types_vendorInfoKey__2WMeJ:first-child {
  margin-top: 0;
}

.add-product-data-types_inputFile__rwrTr {
  margin-bottom: 16px;
}

.add-product-data-types_mandatoryPackagingField__Awsc0 {
  margin-bottom: 1em;
}

.add-product-data-types_mandatoryPackagingField__Awsc0 label {
  margin-bottom: 0;
}

.list-product-data-types_mainDiv__TiSrV {
  margin-top: 10px;
}

.list-product-data-types_inline__AguFc {
  display: inline-block;
  vertical-align: center;
  font-weight: bold;
}

.list-product-data-types_forIds__2nZKt {
  width: 15%;
}

.list-product-data-types_actionButton__W1fAH {
  padding: 2px;
  cursor: pointer;
  margin: 2px;
}

.list-product-data-types_table__2NqpU {
  margin: auto;
}

.list-product-data-types_vendorInfoKey__2UIZy {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-weight: bold;
  vertical-align: top;
  position: absolute;
  left: 15px;
}

.list-product-data-types_vendorInfoValue__3XTv8 {
  display: inline-block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 15px;
  vertical-align: top;
}

.list-product-data-types_vendorEditKey__2_Alp {
  display: inline-block;
  font-weight: bold;
  vertical-align: top;
  position: absolute;
  top: 7.5px;
  left: 15px;
}

.list-product-data-types_vendorEditValue__28b_- {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 7.5px;
  vertical-align: top;
}

.list-product-data-types_input__1k-_E {
  vertical-align: top;
  width: 40%;
  display: inline-block;
  padding: 12px 20px;
  border: 1px solid var(--main-bg-color);
  border-radius: 4px;
  box-sizing: border-box;
}

.list-product-data-types_vendorInfo__3XYxL {
  width: 100%;
}

.list-product-data-types_vendorEdit__vBey0 {
  width: 100%;
  padding: 34px;
}

.product-data-types_dataTypesList__3Bu6c {
  margin-top: 1.5em;
}

.product-data-types_inputElement__19Flt {
  display: inline-block;
  vertical-align: top;
}

.product-data-types_idInput__2pNsa {
  margin: 10px 0 10px 5%;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  border-color: var(--main-bg-color);
}

.product-data-types_idInputDiv__1R3AY {
  display: inline-block;
  vertical-align: top;
  width: 60%;
}

.product-data-types_label__1B5KY {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 35%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.product-data-types_header__3wrQ5 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  position: relative;
}

.product-data-types_switch__2P3Xn {
  bottom: 0;
  padding: 0;
  position: absolute;
  right: 0;
}

.promote-ui_descriptionDiv__7huXt {
  text-align: left;
}

.promote-ui_descTitle__K6Tg3 {
  font-size: 24px;
  font-weight: bold;
}

.promote-ui_desc__mr9mm {
  font-size: 18px;
}

.promote-ui_fileInput__1MGsP {
  margin: 0 0 1em;
}

.promote-ui_instaInput__15Bei {
  margin: 30px 0;
  margin-top: 30px;
  text-align: left;
}

.promote-ui_instaInput__15Bei label {
  font-size: 24px;
  font-weight: normal;
}

.promote-ui_vendorName__2rV4z {
  font-size: 28px;
  font-weight: bold;
  margin: 10px 0;
}

.promote-ui_mainRow__JSqv8 {
  margin: 30px -15px 0;
}

.promote-ui_image__VL_uN {
  width: 100%;
}

.promote-ui_imageGallery__3Yj1z {
  margin: 10px 0 20px 0;
}

.promote-ui_imageCol__30YM7 {
  position: relative;
}

.promote-ui_removeImage__226t7 {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  transition: 0.2s;
}

.promote-ui_removeImage__226t7:hover {
  background-color: var(--main-border-color);
}

.colection-card_card__3v7zE {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: relative;
}

.colection-card_cardDiv__eyg8V {
  margin-top: 30px;
  padding: 10px 2.5%;
  display: inline-block;
  box-sizing: border-box;
  width: calc((100% - (4 * 2.5%)) / 4);
}

.colection-card_mainImage__12qKt {
  height: 250px;
}

.colection-card_card__3v7zE:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.colection-card_container__2IoEh {
  /*padding: 2px 16px;*/
  position: relative;
  top: -2px;
}

.colection-card_alignLeft__1o0oc {
  position: absolute;
  left: 0;
}

.colection-card_alignRight__L9Lxl {
  position: absolute;
  right: 0;
}

.colection-card_approved__1EPE5 {
  left: 0;
  right: 0;
  width: 100% !important;
}

.colection-card_header__VWbWe {
  height: 50px;
  background-color: var(--main-04-color);
}

.colection-card_submit__3ILIx {
  background-color: var(--main-lightGreen-color); /* Green background */
  border: 1px solid var(--main-green-color); /* Green border */
  color: white; /* White text */
  padding: 12px 20px; /* Some padding */
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

.colection-card_danger__1K--H {
  background-color: var(--main-pink-color); /* Green background */
  border: 1px solid var(--main-red-color); /* Green border */
  color: white; /* White text */
  padding: 12px 20px; /* Some padding */
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

.colection-card_groupButton__14KIe:after {
  content: '';
  clear: both;
  display: table;
}

.colection-card_groupButton__14KIe button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Add a background color on hover */
.colection-card_submit__3ILIx:hover {
  background-color: var(--main-lightGreen-color);
}

.colection-card_danger__1K--H:hover {
  background-color: var(--main-red-color);
}

@media screen and (max-width: 1024px) {
  .colection-card_cardDiv__eyg8V {
    padding: 10px 2.5%;
    display: inline-block;
    box-sizing: border-box;
    width: calc((100% - (4 * 2.5%)) / 3);
  }
}

@media screen and (max-width: 800px) {
  .colection-card_cardDiv__eyg8V {
    padding: 10px 2.5%;
    display: inline-block;
    box-sizing: border-box;
    width: calc((100% - (4 * 2.5%)) / 2);
  }
}

@media screen and (max-width: 500px) {
  .colection-card_column__31xMM {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .colection-card_column__31xMM {
    width: 100%;
  }

  .colection-card_danger__1K--H {
    width: 100%;
    display: block;
    position: relative;
  }

  .colection-card_submit__3ILIx {
    width: 100%;
    display: block;
    position: relative;
  }
}

.colection-card_title__1wMaz {
  font-size: 24px;
  display: block;
  margin: 0 auto;
  background-color: transparent;
  position: absolute;
  width: 100%;
  top: 5px;
  font-weight: bold;
}

.colection-card_action__2ugQs {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
}

.colection-card_noCollection__3oHSt {
  color: var(--main-bg-color);
  margin-top: 40px;
  font-size: 24px;
}

.vendor_leftLinks__2JRxr {
  position: absolute;
  left: 0;
}

.vendor_rightLinks__1exLJ {
  position: absolute;
  right: 0;
}

.vendor_newCollection__1X8i5 {
  margin-top: 50px;
}

.vendor_label__uKZD1 {
  text-align: left !important;
  font-size: x-large;
  font-weight: bold;
  margin: 0;
  display: inline-block;
}

.vendor_labelName__29j7h {
  text-align: left !important;
  font-size: x-large;
  margin: 0;
  display: inline-block;
}

.vendor_input__1K6Rw {
  display: inline-block;
  width: 75%;
}

.vendor_submitButton__2O4KE {
  vertical-align: top;
  display: inline-block;
  width: 20%;
  margin-left: 3%;
}

.vendor_search__3SABa {
  margin-top: 20px;
}

.vendor_fileInput__E1QlF {
  padding: 30px 0 30px 10px;
}

.vendor_labelForFileInputs__12O8C {
  text-align: left;
  font-size: 18px;
}

.vendor_popover__gzpjW {
  width: 50%;
}

.vendor_collection__2aN3L {
  cursor: pointer;
  background-color: rgba(183, 153, 97, 0.2);
}

.vendor_popHeader__21XuM {
  background-color: var(--main-bg-color);
}

.vendor_popBody__3C80B {
  background-color: var(--main-03-color);
}

.vendor_actions__SKK5I {
  position: absolute;
  right: 5%;
  bottom: 5%;
  top: 25%;
  width: 50px;
}

.vendor_image__WfY7z {
  display: inline-block;
  width: 100%;
  max-height: 150px;
}

.vendor_cardBody__2DmJ2 {
  height: 220px;
}

.vendor_imageBorder__2Bpfy {
  border: var(--main-bg-color) solid 2px;
  display: inline-block;
  width: 20%;
  margin: 4% 0.25vw;
  vertical-align: top;
}

.vendor_removeImage__1k76e {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.vendor_buttons__2RAA4 {
  margin: 10px 0;
}

.vendor_action__vXXT_ {
  cursor: pointer;
  transition: all 0.4s ease;
  margin: 0 2.5px;
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 2px;
}

.vendor_action__vXXT_:hover {
  background-color: var(--main-07-color);
}

.vendor_navLinks__2vQSg:hover {
  text-decoration: none;
}

.vendor_navLinks__2vQSg:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  bottom: 0;
  visibility: hidden;
  background-color: black;
  -webkit-transition: all 0.1s ease;
  color: var(--main-bg-color);
  transition: all 0.3s ease;
  transform: scaleX(0);
  text-decoration: none;
}

.vendor_navLinks__2vQSg:hover:after {
  visibility: visible;
  transform: scaleX(1);
  text-decoration: none;
}

.vendor_badge__20asE {
  font-size: 14px;
  cursor: pointer;
}

.bio-css_mainDivVideo__2gXCH {
  width: 100%;
  margin-left: 15px;
  border: dashed 1px var(--main-border-color);
}

.bio-css_video__138P4 {
  padding: 10px;
  width: 40%;
}

.vendor-bio_errorText__mk9c5 {
  color: var(--main-red-color);
}

.vendor-bio_mediaGalleries__Zvag5 {
  margin-top: 2em;
}

.vendor-bio_textArea__TsdI0 {
  padding: 5px 20px;
  height: 320px !important;
}

.vendor-bio_history__CUv2V {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
}

.vendor-bio_pageNav__fr9oG {
  margin: 0 0 0.5em -1em;
}

.vendor-bio_photoAndVideo__2Ko99 {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
}

.vendor-bio_saveButton__10h1L {
  outline: none;
}

.vendor-bio_photo__1pJN7 label {
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 178.5px;
  height: 178.5px;
  padding: 50px 10px;
  color: var(--main-bg-color);
  text-align: center;
  border: dashed 1px #8f8f8f;
  background-color: #eeeeee;
  cursor: pointer;
  outline: 0;
}

.vendor-bio_uploadBlock__3svUa {
  color: var(--main-bg-color);
  margin: 5px 0 15px;
}
.vendor-bio_uploadBlock__3svUa label {
  border: 0 none;
  font-weight: normal;
  height: auto;
  margin: 0;
  padding: 0;
  width: auto;
}

.vendor-collection-list_hd__3dsn5 {
  align-items: center;
  display: flex;
}
.vendor-collection-list_hd__3dsn5 > h3 {
  margin: 0;
}

.vendor-collection-list_main__3g1Xa {
  margin: 0 auto;
  text-align: left;
}

.vendor-collection-list_link__2uM1f {
  text-decoration: none;
}

.vendor-collection-list_link__2uM1f:hover {
  text-decoration: none;
  color: #2d2d2d;
}

.vendor-collection-list_message__1eCDb {
  color: var(--main-darkRed-color);
}

.vendor-collection-list_button__1B2xd {
  margin: 0 0.5em 0.5em 0;
}

.vendor-collection-list_borderBottom__3BE-B {
  display: block;
  margin: 0 -15px 1.5em;
  min-height: 50px;
  padding: 10px 0;
  position: relative;
}
.vendor-collection-list_borderBottom__3BE-B:after,
.vendor-collection-list_buttons__DwR2O:after,
.vendor-collection-list_productList__7-qf4:after {
  border-bottom: 1px var(--main-border-color) dashed;
  bottom: 0;
  content: '';
  left: 15px;
  position: absolute;
  right: 15px;
}

.vendor-collection-list_buttons__DwR2O {
  padding: 0 0 1em;
  position: relative;
}
.vendor-collection-list_buttons__DwR2O:after {
  left: 0;
  right: 0;
}

.vendor-collection-list_pageNav__3MiIk {
  margin: 0 -15px;
}

.vendor-collection-list_productList__7-qf4 {
  margin-bottom: 1.5em;
  padding: 0 0 1em;
  position: relative;
}

.vendor-collection-list_vendorActions__1uv7X {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .vendor-collection-list_vendorActions__1uv7X {
    justify-content: flex-start;
    margin-top: 0.5em;
  }
}

.vendor-collection-list-card_card__3Koz3 {
  text-align: center;
  margin: 10px 0;
}

.vendor-collection-list-card_image__19SNw {
  width: 100%;
}

.vendor-collection-list-card_starDiv__wejE7 {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #fff;
  border: 1px var(--main-border-color) dashed;
  background: var(--main-bg-color);
}

.vendor-collection-list-card_cardBody__32F42 {
  padding: 0;
  background-color: var(--main-04-color);
}

.vendor-collection-list-card_cardHeader__pWcNb {
  background-color: var(--main-07-color);
  border-radius: 0;
}

