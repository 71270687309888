.feedback {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
}
.feedbackWrap {
  position: relative;
}

.horizontal,
.horizontal > .input {
  align-items: center;
  display: flex;
}

.horizontal .reversed > .inputField {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.horizontal .reversed > .inputField label {
  margin-bottom: 0;
}
.horizontal label.label {
  font-size: 1em;
  margin: 0.1em 0 0;
  text-transform: none;
}
.horizontal input {
  margin: 0 0 0 0.4em;
  position: static;
  width: auto;
}
.horizontal .reversed .inputField > input,
.horizontal .reversed .inputField .inputWrap {
  margin: 0 0.4em 0 0;
}

.horizontal .nextNode {
  margin: 0 0 0 0.4em;
}

.input input,
.input select,
.input textarea {
  background-color: #f1f2f2;
  border-color: #f1f2f2;
  font-size: 0.875em;
  height: auto;
  letter-spacing: 0.072em;
  line-height: 1.15;
  min-width: 6em;
  padding: 1.36em 1.14em;
}

.input input::placeholder {
  color: #ccc;
}

.input input {
  height: 56px;
}

.input input[type='checkbox'],
.input input[type='radio'] {
  height: auto;
  min-width: auto;
}
.input select {
  padding-bottom: 1.14em;
  padding-top: 1.14em;
}

.inputField {
  width: 100%;
}

.inputWrap {
  position: relative;
}
.inputWrap > input {
  margin: 0;
  padding-right: 1.25em;
}

label.label {
  margin: 0 0 1em;
}

.label {
  display: flex;
  align-items: center;
}

.labelAfter {
  margin: 0 0 0 0.3em;
}

.numberChanges {
  bottom: 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  padding: 0 0.25em 0 0;
  position: absolute;
  right: 0;
  top: 0;
}
.numberChangesBtn {
  cursor: pointer;
  width: 0.75em;
}
.numberChangesDisabled .numberChangesBtn {
  color: #aeaeae;
}

.valueLengthLimit {
  margin: 0 0 0 0.5em;
}

.withValueLengthLimit {
  display: flex;
}
