.card {
  text-align: center;
  margin: 10px 0;
}

.image {
  width: 100%;
}

.starDiv {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #fff;
  border: 1px var(--main-border-color) dashed;
  background: var(--main-bg-color);
}

.cardBody {
  padding: 0;
  background-color: var(--main-04-color);
}

.cardHeader {
  background-color: var(--main-07-color);
  border-radius: 0;
}
