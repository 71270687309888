.table {
  border: 0 none;
  font-family: 'brandon-grotesque';
  text-transform: uppercase;
}

.table th,
.table tbody tr,
.table thead tr {
  background: none;
}
.table td,
.table thead th {
  border: 0 none;
}

.table tbody tr:hover td {
  background: #f1f2f2;
}

.table td {
  color: #9d9e9e;
  font-size: 0.75em;
  letter-spacing: 0.128em;
  vertical-align: middle;
}

.table thead th {
  color: #9d9e9e;
  font-size: 0.625em;
  font-weight: normal;
  letter-spacing: 0.129em;
  line-height: 1.6;
  text-transform: uppercase;
  vertical-align: middle;
}
