.submitButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.pickupLocations {
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  color: #555;
}
