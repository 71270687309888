.item {
  color: #2d2d2d;
  display: block;
  padding: 0.24em 1em;
  transition: all 0.4s ease;
  text-decoration: none;
}

a.item {
  cursor: pointer;
}

.item:hover,
.current .item {
  background-color: var(--main-07-color);
  color: #2d2d2d;
}
