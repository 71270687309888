.cta {
  margin: 2em 0 0;
  text-align: right;
}

.description p {
  margin: 0.5em 0 0;
  max-width: 700px;
}

.dropIn {
  margin: 1em 0 0;
}

.page {
  margin: 0.625em 0 0;
  text-align: left;
  margin: 0 auto;
}

.plansList {
  margin: 2em 0 1em;
}
.plansList h1 {
  font-size: 2em;
}
.plansList > li {
  width: calc(100% - 1em);
}

.unsubscribe {
  margin: 0 0 0 1em;
}

@media screen and (min-width: 600px) {
  .plansList > li {
    width: calc(50% - 1em);
  }
}
