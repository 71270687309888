.label,
.quantity {
  color: #000;
  font-size: 0.75em;
  letter-spacing: 0.15em;
  line-height: 1.6;
  text-transform: uppercase;
}

.label {
  margin: 0 0 1.5em;
}

.product {
  margin: 0 0 0.5em;
}

.quantity {
  margin-top: 16px;
}

.text {
  color: #919191;
  font-size: 0.875em;
  line-height: 1.286;
}

.wrap {
  background: #f1f2f2;
  font-family: 'brandon-grotesque';
  padding: 15px 0;
}
.details {
  margin-top: 0.5em;
}
.details p {
  display: flex;
  align-items: center;
  font-size: 0.875em;
}
.details svg {
  margin-right: 0.525em;
}

.header {
  color: #000;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.customization {
  display: flex;
  flex-direction: column;
}

.fulfilmentLinks li {
  display: flex;
  margin-left: 0;
}
