.status {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.detail {
  margin-bottom: 10px;
}

.statusActive {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--main-green-color);
}

.statusInactive {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--main-darkRed-color);
}

.pageNav {
  margin: 0 0 0.5em -1em;
}

.button {
  margin-top: 20px;
  display: block;
  outline: none;
}
