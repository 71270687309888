.buttons {
  margin: 1em 0 0;
}
.buttons > button {
  margin: 0 0.5em 0.5em 0;
}

.input {
  margin-bottom: 1em;
}

.label {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 0 0.5em;
}
