.card {
  margin: 0.5em 0 0;
  text-align: left;
}

.img {
  height: 24px;
  margin: 0 0.5em 0 0;
  width: 24px;
}

.values {
  font-size: 1.2em;
  margin: 0;
}

.bio {
  margin: 0;
}

.title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
