.dataTypesList {
  margin-top: 1.5em;
}

.inputElement {
  display: inline-block;
  vertical-align: top;
}

.idInput {
  margin: 10px 0 10px 5%;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  border-color: var(--main-bg-color);
}

.idInputDiv {
  display: inline-block;
  vertical-align: top;
  width: 60%;
}

.label {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 35%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  position: relative;
}

.switch {
  bottom: 0;
  padding: 0;
  position: absolute;
  right: 0;
}
