.bold {
  font-weight: bold;
}

.buttonCancel {
  margin: 0 2.857em 0 0;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 3.4375em 0 0;
}

.children {
  margin: 3.25em 0 0;
}

.status {
  margin-bottom: 1.5em;
}

@media screen and (max-width: 500px) {
  .buttons {
    display: block;
  }

  .buttonsGroup {
    margin: 1em 0 0;
  }
}
