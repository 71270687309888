.content {
  align-items: center;
  display: flex;
  font-size: 0.75em;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 0.12em;
  line-height: 1.3;
  margin: 0 0 0 0.75em;
  text-transform: uppercase;
}

.data {
  align-items: center;
}

.hits {
  color: #000;
  display: flex;
  font-weight: normal;
  margin-left: 2px;
}
.hitsIcon {
  color: #9d9e9e;
  display: flex;
  margin: 0 0.5em 0 0;
  width: 1em;
}

.image {
  background: #fff;
  height: 3em;
  line-height: 3em;
  width: 3em;
}
.image a {
  display: inline-block;
  height: 100%;
  width: 100%;
}
.image img {
  max-height: 100%;
  max-width: 100%;
}

.item {
  margin-bottom: 1.5em;
}
.item a {
  text-decoration: none;
}
