.link {
  min-width: 235px !important;
}

.ButtonsList {
  border-bottom: var(--main-bg-color) solid 0.5px;
  padding: 15px 0;
}

.mainDiv {
  margin-top: 20px;
}
