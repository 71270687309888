.mainDiv {
  position: relative;
  width: 94%;
  left: 3%;
  right: 3%;
}

.leftDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 49%;
}

.rightDiv {
  position: absolute;
  right: 0;
  top: 0;
  width: 49%;
}

.pageTitle {
  margin-bottom: 10px;
}

.pageTitle,
.pageTitle h1 {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
}
.pageTitle h1 {
  font-family: 'BentonModDisp SB', serif;
  font-size: 1.285em;
  letter-spacing: 0.03em;
  margin: 0;
}

.pageTitle1 {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  margin: 15px 0 0 25px;
  display: block;
  padding: 0;
  color: var(--main-bg-color);
}

.container {
  margin-left: 40%;
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: var(--main-blue-color);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 750px) {
  .rightDiv {
    position: relative;
    display: block;
    width: 100%;
  }

  .leftDiv {
    position: relative;
    display: block;
    width: 100%;
    min-height: 360px;
  }
}
