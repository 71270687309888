.bottomString {
  bottom: 0;
  color: #000;
  font-size: 0.75em;
  font-weight: bold;
  left: 0;
  letter-spacing: 0.15em;
  line-height: 1.333;
  margin: 0;
  position: absolute;
}

.cnt {
  position: relative;
}

.image {
  display: inline-block;
  margin: 0 0.75em 0 0;
  overflow: hidden;
}
.image img {
  max-height: 100%;
  max-width: 100%;
}

.mod1 .cnt {
  padding: 0 0 1em;
}
.mod1 .image {
  margin-right: 0.625rem;
}

.name {
  color: #000;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.128em;
  line-height: 1.333;
  margin: 0;
  text-transform: uppercase;
}

.id {
  font-size: 0.75em;
  margin: 0;
  text-transform: uppercase;
}

.wrap {
  align-items: center;
  display: flex;
}
.wrap.mod1 {
  align-items: initial;
}
