.mainDiv {
  margin-top: 5px;
  background: #222222;
  padding: 20px 0 20px 0;
  border-top: var(--main-bg-color) solid 3px;
  position: relative;
  width: 100%;
}

.allRights {
  display: block;
  text-align: center;
  color: #bcbcbc;
}

.absolute {
  position: absolute;
  bottom: 0;
}

.absoluteDiv {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
}

.unShownDiv {
  z-index: -1;
  width: 0;
  background-color: var(--main-darkRed-color);
  position: relative;
  top: 0;
  height: 100vh;
}
