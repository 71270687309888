.item {
  margin-bottom: 1.5em;
  text-align: center;
}
.imageSquare {
  height: 120px;
  width: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.imageSquare span {
  color: transparent;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
