.main {
  width: 96%;
  margin: 0 auto;
  text-align: left;
}

.header {
  margin-top: 10px;
}

.actions {
  text-align: right;
}

.cardRow {
  margin-top: 20px;
}

.actions button {
  margin-right: 2.5px;
}

.home {
  margin-left: 10px;
  display: inline-block;
  font-size: 24px;
}

.h2 {
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-family: 'bentonmod';
  color: var(--main-bg-color);
  margin-top: 3%;
}
