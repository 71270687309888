.errorText {
  color: var(--main-red-color);
}

.mediaGalleries {
  margin-top: 2em;
}

.textArea {
  padding: 5px 20px;
  height: 320px !important;
}

.history {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
}

.pageNav {
  margin: 0 0 0.5em -1em;
}

.photoAndVideo {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
}

.saveButton {
  outline: none;
}

.photo label {
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 178.5px;
  height: 178.5px;
  padding: 50px 10px;
  color: var(--main-bg-color);
  text-align: center;
  border: dashed 1px #8f8f8f;
  background-color: #eeeeee;
  cursor: pointer;
  outline: 0;
}

.uploadBlock {
  color: var(--main-bg-color);
  margin: 5px 0 15px;
}
.uploadBlock label {
  border: 0 none;
  font-weight: normal;
  height: auto;
  margin: 0;
  padding: 0;
  width: auto;
}
