.close {
  display: inline-block;
  position: relative;
}
.close:after {
  background-color: #fff;
  content: '';
  height: 50%;
  left: 25%;
  position: absolute;
  top: 25%;
  width: 50%;
}

.icon {
  color: #000;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
