.card {
  font-family: 'brandon-grotesque';
  text-transform: uppercase;
  width: 120px;
}
.card p {
  color: #000;
  font-size: 0.75em;
  line-height: 1.333;
}
.card .status {
  font-size: 0.625em;
  line-height: 1;
}

.img {
  height: 120px;
  overflow: hidden;
  margin: 0;
}
.img > img {
  max-height: 100%;
  max-width: 100%;
}

.name {
  color: #000;
  font-weight: bold;
  letter-spacing: 0.128em;
  margin: 0.5em 0 0;
}

.price {
  letter-spacing: 0.15em;
  margin: 0.333em 0 0;
}

.status {
  font-weight: bold;
  letter-spacing: 0.15em;
  margin: 1.2em 0 0;
}
