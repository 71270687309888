.data {
  align-items: center;
  display: flex;
}

.icon {
  margin: 0 0 0 0;
}

.label,
.value {
  font-size: 0.625em;
  text-transform: uppercase;
}

.label {
  color: #9d9e9e;
  letter-spacing: 0.129em;
  line-height: 1.6;
  margin: 0 0 1.4em;
}

.toggler {
  align-items: center;
  color: #b69864;
  cursor: pointer;
  display: flex;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.5;
  text-transform: uppercase;
}

.value {
  color: #b79961;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 2.2;
  margin: 0 2em 0 0;
}

.wrap {
  font-family: 'brandon-grotesque';
}
