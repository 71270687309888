.leftLinks {
  position: absolute;
  left: 0;
}

.rightLinks {
  position: absolute;
  right: 0;
}

.newCollection {
  margin-top: 50px;
}

.label {
  text-align: left !important;
  font-size: x-large;
  font-weight: bold;
  margin: 0;
  display: inline-block;
}

.labelName {
  text-align: left !important;
  font-size: x-large;
  margin: 0;
  display: inline-block;
}

.input {
  display: inline-block;
  width: 75%;
}

.submitButton {
  vertical-align: top;
  display: inline-block;
  width: 20%;
  margin-left: 3%;
}

.search {
  margin-top: 20px;
}

.fileInput {
  padding: 30px 0 30px 10px;
}

.labelForFileInputs {
  text-align: left;
  font-size: 18px;
}

.popover {
  width: 50%;
}

.collection {
  cursor: pointer;
  background-color: rgba(183, 153, 97, 0.2);
}

.popHeader {
  background-color: var(--main-bg-color);
}

.popBody {
  background-color: var(--main-03-color);
}

.actions {
  position: absolute;
  right: 5%;
  bottom: 5%;
  top: 25%;
  width: 50px;
}

.image {
  display: inline-block;
  width: 100%;
  max-height: 150px;
}

.cardBody {
  height: 220px;
}

.imageBorder {
  border: var(--main-bg-color) solid 2px;
  display: inline-block;
  width: 20%;
  margin: 4% 0.25vw;
  vertical-align: top;
}

.removeImage {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.buttons {
  margin: 10px 0;
}

.action {
  cursor: pointer;
  transition: all 0.4s ease;
  margin: 0 2.5px;
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 2px;
}

.action:hover {
  background-color: var(--main-07-color);
}

.navLinks:hover {
  text-decoration: none;
}

.navLinks:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  bottom: 0;
  visibility: hidden;
  background-color: black;
  -webkit-transition: all 0.1s ease;
  color: var(--main-bg-color);
  transition: all 0.3s ease;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  text-decoration: none;
}

.navLinks:hover:after {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  text-decoration: none;
}

.badge {
  font-size: 14px;
  cursor: pointer;
}
