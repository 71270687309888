.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  position: relative;
}

.cardDiv {
  margin-top: 30px;
  padding: 10px 2.5%;
  display: inline-block;
  box-sizing: border-box;
  width: calc((100% - (4 * 2.5%)) / 4);
}

.mainImage {
  height: 250px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  /*padding: 2px 16px;*/
  position: relative;
  top: -2px;
}

.alignLeft {
  position: absolute;
  left: 0;
}

.alignRight {
  position: absolute;
  right: 0;
}

.approved {
  left: 0;
  right: 0;
  width: 100% !important;
}

.header {
  height: 50px;
  background-color: var(--main-04-color);
}

.submit {
  background-color: var(--main-lightGreen-color); /* Green background */
  border: 1px solid var(--main-green-color); /* Green border */
  color: white; /* White text */
  padding: 12px 20px; /* Some padding */
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

.danger {
  background-color: var(--main-pink-color); /* Green background */
  border: 1px solid var(--main-red-color); /* Green border */
  color: white; /* White text */
  padding: 12px 20px; /* Some padding */
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

.groupButton:after {
  content: '';
  clear: both;
  display: table;
}

.groupButton button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Add a background color on hover */
.submit:hover {
  background-color: var(--main-lightGreen-color);
}

.danger:hover {
  background-color: var(--main-red-color);
}

@media screen and (max-width: 1024px) {
  .cardDiv {
    padding: 10px 2.5%;
    display: inline-block;
    box-sizing: border-box;
    width: calc((100% - (4 * 2.5%)) / 3);
  }
}

@media screen and (max-width: 800px) {
  .cardDiv {
    padding: 10px 2.5%;
    display: inline-block;
    box-sizing: border-box;
    width: calc((100% - (4 * 2.5%)) / 2);
  }
}

@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .column {
    width: 100%;
  }

  .danger {
    width: 100%;
    display: block;
    position: relative;
  }

  .submit {
    width: 100%;
    display: block;
    position: relative;
  }
}

.title {
  font-size: 24px;
  display: block;
  margin: 0 auto;
  background-color: transparent;
  position: absolute;
  width: 100%;
  top: 5px;
  font-weight: bold;
}

.action {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
}

.noCollection {
  color: var(--main-bg-color);
  margin-top: 40px;
  font-size: 24px;
}
