.SearchForm {
  padding: 0 18px 0 0;
  position: relative;
}

.SearchForm__Button {
  background: url(static/images/search.svg) no-repeat 50% 50%;
  border: 0 none;
  bottom: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
}

.SearchForm__Field {
  background: none;
  border: 0 none;
  color: #9d9e9e;
  height: 100%;
  width: 11em;
}
.SearchForm__Field:focus {
  background: none;
  box-shadow: none;
}
.SearchForm__Field > div {
  height: 100%;
  margin: 0;
}
.SearchForm__Field > div > label {
  display: none;
}
