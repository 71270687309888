.mainTitle {
  font-size: 3.33333333rem;
  font-family: 'bentonmod';
  color: #000000;
}

.p {
  font-family: 'brandon-grotesque';
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  color: #2d2d2d;
  display: inline-block;
}

.a {
  text-decoration: none;
  color: var(--main-bg-color);
}

.textContainer {
  margin-top: 5%;
}

.h2 {
  font-size: 4rem;
  font-family: 'bentonmod';
  color: var(--main-bg-color);
  margin-top: 3%;
}

.footerDiv {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: max-content;
}
