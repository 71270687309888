.alignLeft {
  position: absolute;
  left: 0;
}

.alignRight {
  position: absolute;
  right: 0;
}

.SKU {
  background-color: var(--main-04-color);
  padding: 0;
  text-align: center;
  font-size: x-large;
  height: 45px;
  font-weight: bold;
}

.submit {
  background-color: var(--main-lightGreen-color);
  border: 1px solid var(--main-green-color);
  color: white;
  padding: 12px 20px;
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

.warning {
  background-color: var(--warning);
  border: 1px solid var(--warning);
  color: black;
  padding: 12px 20px;
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

.mainCol {
  margin-bottom: 15px;
}

.danger {
  background-color: var(--main-pink-color);
  border: 1px solid var(--main-red-color);
  color: white;
  padding: 12px 20px;
  width: 50%;
  transition: 0.3s;
  font-size: 14px;
}

submit:hover {
  background-color: var(--main-lightGreen-color);
}

.danger:hover {
  background-color: var(--main-red-color);
}

@media screen and (max-width: 450px) {
  .danger {
    width: 100%;
    display: block;
    position: relative;
  }

  .submit {
    width: 100%;
    display: block;
    position: relative;
  }
}

.body {
  padding: 0;
}

.footer {
  position: relative;
  height: 45px;
  background-color: transparent;
  border: none;
}

.footer button {
  top: 0;
}
