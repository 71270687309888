.mainDiv {
  position: fixed;
  background-color: var(--loading-color);
  margin: 0;
  padding: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1051;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progressStatus,
.progressBarWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.progressStatus {
  top: 0;
  line-height: 2;
}

.progressBarWrapper {
  height: 2rem;
  width: 75%;
  max-width: 600px;
  border: 1px solid var(--main-golden);
  border-radius: 5px;
  overflow: hidden;
}

.progressBar,
.progressBarColor {
  height: 100%;
  width: 100%;
  background-color: var(--main-bg-light);
}

.progressBarColor {
  background-color: var(--main-golden);
}
