.mainDivVideo {
  width: 100%;
  margin-left: 15px;
  border: dashed 1px var(--main-border-color);
}

.video {
  padding: 10px;
  width: 40%;
}
