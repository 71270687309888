.hd {
  font-size: 1.2em;
  margin: 0 0 0.5em !important;
  text-align: center;
}

.intro {
  margin-bottom: 3em !important;
}

.layout > div {
  margin-bottom: 2em;
}

@media screen and (min-width: 767px) {
  .hd {
    font-size: 1.5em;
  }
}
