.colNameLink,
.colNameLink:hover {
  color: #212529;
}
.colNameLink:hover {
  text-decoration: none;
}

.main {
  width: 96%;
  margin: 0 auto;
  text-align: left;
}

.main h4 {
  display: inline;
  margin: 20px 0;
  font-weight: bold;
}

.header {
  position: relative;
  padding: 10px 0;
}
