.table {
  font-family: 'brandon-grotesque';
  margin: 0;
  overflow: hidden;
}

.table,
.table td,
.table thead th {
  border: 0 none;
  vertical-align: middle;
}

.table th,
.table > tbody > tr,
.table thead tr {
  background: none;
}

.table th {
  color: #9d9e9e;
  font-size: 0.625em;
  font-weight: normal;
  letter-spacing: 0.129em;
  line-height: 1.6;
  text-transform: uppercase;
}
