.form input,
.form textarea,
.formInputFile {
  background: #fff;
}

td.detailTd {
  padding: 0;
  position: relative;
}

.orderProduct {
  margin: 0 0 1.5em;
}

.packageDimensions,
.shippingDate {
  color: #9d9e9e;
  font-size: 0.75em;
  letter-spacing: 0.128em;
}

.packagedReadyImages {
  display: flex;
  margin: 0;
}
.packagedReadyImages > li {
  border: 1px solid #f1f2f2;
  height: 48px;
  margin: 0 0 0 1em;
  overflow: hidden;
  width: 48px;
}
.packagedReadyImages > li:first-child {
  margin-left: 0;
}
.packagedReadyImages > li img {
  max-height: 100%;
  max-width: 100%;
}

.part {
  padding: 4.5em 0 0;
}

.price,
.status {
  color: #000;
  font-size: 0.625em;
  font-weight: bold;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.price {
  font-size: 0.75em;
}

.togglerOn {
  position: absolute;
  right: 1em;
  top: 1em;
}

.togglerTd {
  text-align: right;
}

.topList {
  left: calc(15px + 15em);
  position: absolute;
  right: 15px;
  text-align: right;
  top: -25.5px;
}
