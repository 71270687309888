.fileInput {
  margin: 1em 0;
}

.mainDiv {
  text-align: left;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px -15px;
}

.productCol {
  border: dashed 1px var(--main-border-color);
  margin: 5px 0;
  padding: 15px 5px;
}

.prodImage {
  width: 100%;
  min-height: 80%;
}

.actionsCol button {
  margin: 10px 0;
}

.leftTitle {
  text-align: left;
}

.rightTitle {
  text-align: right;
}
