.icon {
  align-items: center;
  display: flex;
  margin: 0 0 0 0.3em;
}

.list {
  color: #b69864;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.5;
  margin: 0;
  text-transform: uppercase;
}
.list a {
  color: inherit;
  text-decoration: none;
  transition: color 250ms;
}

.list a:hover {
  color: #80683f;
}

.list > li {
  align-items: center;
  display: inline-flex;
  margin: 0 0 0.5em 2.667em;
}
.list > li:first-child {
  margin-left: 0;
}
