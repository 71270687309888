.button {
  display: flex;
  justify-content: flex-end;
}

.col-sm-5,
.col-sm-2 {
  margin-left: 30px;
}

.imageClose {
  border: dashed 1px #8f8f8f;
  padding: 5px;
}

.inputFile {
  margin-bottom: 1em;
}

.label {
  color: var(--main-bg-color);
}
.labelSuccess {
  color: var(--main-success-color);
}

.mainRow {
  margin-top: 30px;
}

.otherImages {
  margin: -1em 0 0 1em;
  position: relative;
  z-index: 1;
}

.uploadBtn {
  height: 40px;
}

.onboardingImage {
  width: 100%;
}

.onImageCol {
  height: max-content;
  margin: 0 0 1em;
  padding: 0 15px;
}

@media screen and (max-width: 576px) {
  .button {
    justify-content: flex-start;
  }
}
