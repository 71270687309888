.barIcon,
.labelFormatterIcon {
  color: #9d9e9e;
  height: 1em;
  margin: 0 0.5em 0 0;
}

.barValue,
.labelFormatter {
  align-items: center;
  display: flex;
}

.toolTip {
  align-items: center;
  display: flex;
}

.toolTipColor {
  border: 1px solid #fff;
  display: inline-block;
  height: 1em;
  margin: 0 0.5em 0 0;
  width: 1em;
}
