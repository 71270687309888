.header {
  margin: 0 auto;
  padding: 5px 0;
}

.logo {
  height: 50px;
  max-width: 150px;
}
.logoLink {
  margin: 0 1em 0 0;
}

.avatar {
  margin-right: 2.5%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition-delay: 1s;
  transition-duration: 1s;
}

.dropdown {
  margin-right: 3%;
  outline: none;
  border: none;
  background: transparent;
}
.dropdownMenu {
  min-width: 12rem;
}
.dropdown .btn-secondary {
  box-shadow: none;
}

.dropbtn {
  background-color: transparent;
  border-radius: 50%;
  padding: 0;
  font-size: 16px;
  border: none;
  transition-delay: 1s;
  transition-duration: 1s;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  transform: translateX(-50%);
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.out {
  content: '\e163';
  width: 30px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.p {
  cursor: pointer;
  color: black;
  display: block;
  margin: 5px;
  padding: 12px 16px;
  text-decoration: none;
  width: auto;
}

.dropdown-content a:hover {
  background-color: var(--main-bg-color);
}

.p:hover {
  background-color: var(--main-bg-color);
  color: #fff;
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.search {
  align-items: center;
  display: flex;
  margin: 0 1em 0 0;
}

.smallLogo {
  border: none;
  height: 40px;
  outline: none;
  width: 40px;
}

@media screen and (max-width: 990px) {
  .dropdown {
    margin-right: 0;
  }

  .dropdown-content {
    transform: translateX(-70%);
  }
}

.link {
  display: flex;
  font-size: 0.875em;
  font-weight: bold;
  height: 56px;
  justify-content: center;
  margin: 0 20px 0 0;
  text-decoration: none;
  text-transform: uppercase;
}

.link-a {
  align-items: center;
  display: flex;
  font-weight: 900;
  letter-spacing: 0.13em;
  position: relative;
}
.link-active {
  color: var(--main-bg-color);
}
.link-a:after {
  content: '';
  position: absolute;
  height: 3px;
  width: 100%;
  left: 0;
  top: -13px;
  visibility: hidden;
  background-color: var(--main-bg-color);
  -webkit-transition: all 0.1s ease;
  color: var(--main-bg-color);
  transition: all 0.3s ease;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  text-decoration: none;
}
.link-a:hover:after,
.link-active:after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  text-decoration: none;
  visibility: visible;
}

.nav-item {
  margin: auto !important;
}

.link a {
  text-decoration: none;
  transition: 0.3s;
}

.modalButton {
  margin: 0 5px;
}

.flagButton {
  border: 0;
  background: transparent;
  margin-left: 0.75rem;
}

.navbar {
  justify-content: space-between;
}

.navbar-lang {
  margin: 0 0 5px;
}

@media screen and (max-width: 768px) {
  .link {
    margin: 0;
  }

  .link-a {
    padding: 0.5em 0;
  }
  .link-a:after {
    top: 0;
  }

  .search {
    justify-content: center;
    margin-right: 0;
  }
}
