.currentPage {
  background-color: var(--main-bg-color);
  color: white;
  font-weight: bold;
}

.paginationLinks {
  text-decoration: none;
}

.paginationLinks:disabled {
  background-color: var(--main-disabled-color);
}

.pagination {
  position: absolute;
  right: 15px;
}
