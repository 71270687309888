.close {
  display: none;
  position: absolute;
  right: 0.1em;
  top: 0.1em;
}

.icon {
  display: inline-block;
  position: relative;
}

.icon > img {
  max-width: 100%;
}

.icon:hover > .close {
  display: block;
}
