.mainImage {
  width: 100%;
}

.materialDiv {
  margin: 10px 0;
}

.designerDiv p {
  display: inline-block;
}

.designerDiv {
  margin: 10px 0;
}

.main {
  text-align: left;
}

.imgDiv {
  height: max-content;
  border: dashed 1px var(--main-border-color);
  padding: 0.625em;
}

.currencies {
  margin: 10px 0;
  padding: 0;
}

.otherImages {
  margin: -1em 0 0 1em;
}

p {
  margin-bottom: 0 !important;
}

.parcels {
  margin: 1em 0 0;
}

.prodName {
  color: #f00;
}

.sizing {
  margin: 10px 0;
}

.heights {
  margin: 10px 0;
}

.heights h4 {
  display: inline-block;
  margin-left: 10px;
}

.code {
  overflow: hidden;
}
