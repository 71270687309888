h2 {
  margin-top: 1em;
}

h1.mod2,
h2.mod1 {
  color: #000;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

label {
  color: #000;
  font-size: 0.75em;
  font-weight: 500;
  letter-spacing: 0.1125em;
  text-transform: uppercase;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: 1px solid #f7f7f7;
}

td,
th {
  text-align: left;
  padding: 15px 5px;
}

textarea {
  min-height: 9.375em;
}

th {
  background-color: var(--main-bg-color);
  text-align: left;
}

tr:nth-child(odd) {
  background-color: var(--main-04-color);
}

ul {
  padding: 0;
}

.App {
  text-align: center;
}

.btn:disabled {
  cursor: default;
}

.custom-control {
  align-items: center;
  display: flex;
  cursor: pointer;
}
.custom-control .custom-control-label {
  cursor: pointer;
}
.custom-control .custom-control-input {
  margin: 0;
}
.custom-control .custom-control-label::before {
  top: 0;
}

.form-control {
  /* color: #9D9E9E; */
  color: #555;
}

.innerPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  padding-bottom: 3em;
  text-align: left;
  width: 96%;
}

.noMarginTop {
  margin-top: 0;
}

.page-link,
.page-link:hover {
  color: #000;
}
.page-item.active .page-link,
.page-item.active.disabled .page-link {
  color: #fff;
}
.page-item.active .page-link {
  background-color: #b79961;
  border-color: #b79961;
  cursor: default;
}

.rowAlignBottom > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.rowWide {
  margin-left: -15px;
  margin-right: -15px;
}
