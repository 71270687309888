.mod1 {
  color: #7d7d7f;
  font-size: 0.75em;
}
.mod1 td {
  border-color: #f1f1f1;
}

.rightCell {
  text-align: right;
}

.mod1 > .thead th {
  background: #fafafa;
  border: 0 none;
  color: #7e7d83;
}
