.btn {
  margin: 1.75em 0 0;
  text-align: center;
}

.input {
  margin: 0 0 2.5em;
}
.input input {
  background: #fff;
  border-color: #ced4da;
  font-size: 1em;
  height: 2.375em;
  padding: 0.375em 0.75em;
}
.input label {
  color: #afafaf;
}
