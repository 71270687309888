.buttonPropose {
  margin: 2em 0 0;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
}

.col {
  padding-bottom: 1.5em;
  padding-top: 4.5em;
}

.notFound {
  font-family: 'brandon-grotesque';
}

.ordersTable {
  margin-top: 1.875em;
}

.page {
  padding-bottom: 2em;
}
.page .main h2 {
  font-size: 0.8125em;
}

.paginator {
  margin: 2em 0 0;
}

.productsToDescribe {
  margin: 2.625em -3px 0;
}

.sidebar {
  background: #f1f2f2;
}

.totalCnt {
  margin: 0 -15px 3em;
}

@media screen and (max-width: 768px) {
  .sidebar {
    padding-top: 2em;
  }
}

@media screen and (min-width: 992px) {
  .sidebarCnt {
    padding-left: 3.5em;
  }
}
