.firstRow {
  margin: 10px -15px;
  position: relative;
}
.firstRow button {
  bottom: 1em;
  position: absolute;
  right: 15px;
}

.nav {
  margin: 0 -15px 1.5em;
}

.paginationDiv {
  margin-bottom: 40px;
}

.pagination {
  position: absolute;
  right: 50px;
}

.currentPage {
  background-color: var(--main-bg-color);
  color: white;
  font-weight: bold;
}

.paginationLinks {
  padding: 10px 0;
  text-decoration: none;
}

.paginationLinks a {
  color: black;
  padding: 10px 15px;
  text-decoration: none;
}

.paginationLinks span {
  padding: 10px 15px;
  text-decoration: none;
}

.paginationLinks:disabled {
  background-color: var(--main-disabled-color);
}
