.mainDiv {
  margin-top: 10px;
}

.inline {
  display: inline-block;
  vertical-align: center;
  font-weight: bold;
}

.forIds {
  width: 15%;
}

.actionButton {
  padding: 2px;
  cursor: pointer;
  margin: 2px;
}

.table {
  margin: auto;
}

.vendorInfoKey {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-weight: bold;
  vertical-align: top;
  position: absolute;
  left: 15px;
}

.vendorInfoValue {
  display: inline-block;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 15px;
  vertical-align: top;
}

.vendorEditKey {
  display: inline-block;
  font-weight: bold;
  vertical-align: top;
  position: absolute;
  top: 7.5px;
  left: 15px;
}

.vendorEditValue {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 7.5px;
  vertical-align: top;
}

.input {
  vertical-align: top;
  width: 40%;
  display: inline-block;
  padding: 12px 20px;
  border: 1px solid var(--main-bg-color);
  border-radius: 4px;
  box-sizing: border-box;
}

.vendorInfo {
  width: 100%;
}

.vendorEdit {
  width: 100%;
  padding: 34px;
}
