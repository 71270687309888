.content {
  display: flex;
  width: 16em;
}

.contentText {
  margin: 0 0 0 0.5em;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

.image {
  height: 4em;
  width: 4em;
}

.item {
  cursor: pointer;
}

.menu {
  left: auto !important;
  right: 0 !important;
  top: 3.3em !important;
  transform: unset !important;
}
