.headingSlot {
  display: flex;
  align-items: flex-end;
}

.pageTitle {
  display: flex;
  align-items: center;
}

.pageTitle svg {
  margin-left: 0.625rem;
  height: 1.7rem;
  width: 1.7rem;
}

.artisanStatus_draft,
.artisanStatus_active,
.artisanStatus_inactive,
.artisanPlan {
  margin: 0 10px;
  font-size: 16px;
  font-family: 'brandon-grotesque';
  font-weight: bold;
  letter-spacing: normal;
  text-transform: uppercase;
}

.artisanStatus_draft {
  color: var(--main-golden);
}

.artisanStatus_active {
  color: var(--main-green-color);
}

.artisanStatus_inactive {
  color: var(--main-darkRed-color);
}

.artisanAction {
  margin-left: auto;
  white-space: nowrap;
  line-height: 1;
}

.publishErrors {
  padding-left: 1rem;
  padding-top: 0.625rem;
}

.publishErrors li {
  /* text-transform: capitalize; */
  list-style: disc;
  margin-bottom: 3px;
}

.artisanHeading {
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.artisanHeading h1 {
  display: inline;
}

.artisanHeading svg {
  margin-bottom: 5px;
  flex-shrink: 0;
}

.artisanPlan {
  white-space: nowrap;
}
