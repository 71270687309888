.itemsRest {
  align-items: center;
  bottom: 1.333em;
  display: flex;
  font-size: 0.75em;
  letter-spacing: 0.125em;
  position: absolute;
  right: 0;
  top: 0;
  width: 4em;
}

.wrap {
  color: #9d9e9e;
  padding: 0 3.5em 0 0;
  position: relative;
}
.wrap.noVisibleItems {
  padding-right: 0;
}
