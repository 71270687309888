.list {
  font-family: 'brandon-grotesque';
  margin-left: -15px;
  margin-right: -15px;
}
.list li {
  font-size: 1em;
  margin-bottom: 2em;
}
.list p {
  color: #000;
  display: block;
  font-size: 0.875em;
  letter-spacing: 0.129em;
  line-height: 1.714;
  margin: 0.444em 0;
  width: 100%;
}

.list .mod1 p {
  font-size: 1.125em;
  font-weight: bold;
  line-height: 1.333;
  letter-spacing: 0.15em;
}

.list .label,
.list .mod1 .label {
  color: #9d9e9e;
  font-size: 0.625em;
  font-weight: normal;
  line-height: 1.6;
  margin: 0;
  text-transform: uppercase;
}
