.bg,
.bg:after {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.bg {
  align-items: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.bg:after {
  content: '';
}

.bgWithColor:after {
  background-color: var(--loading-color);
}

.spinnerDown {
  align-items: flex-end;
}

.wrap {
  position: relative;
}
