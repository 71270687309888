.listContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listItem {
  padding: 10px;
}
