.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.list > li {
  margin: 0 0 1em;
}
.list > li.active {
  font-weight: 700;
}
.list p {
  width: 100%;
}

.list.noWrap {
  flex-wrap: nowrap;
}

.mod1 {
  color: #000;
  text-transform: uppercase;
}
.mod1 a {
  color: #000;
  text-decoration: none;
}
.mod1 a:hover {
  text-decoration: underline;
}
