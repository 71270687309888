.customProps {
  padding: 0 0 0 1em;
}

.openTranslations {
  font-size: 24px;
  font-weight: bold;
  padding: 15px 0;
  text-align: left;
  display: block;
}

.otherImages {
  margin: -0.6em 0.5em 0;
}

.paddingBottom {
  padding-bottom: 40px;
}

.loadingWrapper {
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
}

.pageNav {
  margin: 0 0 1em -15px;
}

.vendorBioForProdTranslations {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  display: block;
}

.managerText {
  display: block;
  width: 100%;
}

.managerTextWarning {
  color: var(--main-red-color);
}

.materialAndColor {
  text-align: left;
  font-weight: bold;
  margin-top: 15px;
}

.materialAndColor div {
  margin: 5px 0;
}

.productIdProdTranslations {
  font-size: 24px;
  font-weight: bold;
  padding: 5px 0;
  text-align: left;
  display: block;
}

.text {
  text-align: left;
}

.textArea textarea {
  height: 27vh !important;
}

.buttons {
  margin: 15px;
}

.card {
  display: block;
  margin: 2vh 0 0;
}

.cardCompBio {
  display: block;
  margin-bottom: 2vh;
}

.errorText {
  color: var(--main-red-color);
}

.paginator {
  margin-top: 2em;
}

.productImage {
  width: 100%;
}

.h2 {
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-family: 'bentonmod';
  color: var(--main-bg-color);
  margin-top: 3%;
}

.radioLeft {
  text-align: left;
}

.p {
  display: inline-block;
  margin: 0 10px 0 0;
}
