.button {
  cursor: pointer;
  outline: none;
  padding: 11px 30px;
  transition: 0.3s;
}

.title {
  display: inline-block;
  font-size: 24px;
  margin: 0 1em 0 0;
}

.wrap {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
