.ddItem {
  cursor: pointer;
  text-decoration: none;
}

.wrap {
  align-items: center;
  display: flex;
}

.wrap button {
  font-size: 0.75rem !important;
}

.updateDates {
  font-size: 0.75rem;
}

.updateDates > div {
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #f1f2f2;
}

.updateDates > div:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.lastChanges {
  font-size: 0.75rem;
  max-height: calc(40vh);
  overflow-y: auto;
}

.lastChanges > div {
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.lastChanges > div:last-child {
  border-bottom: 0;
}

.lastChanges > div > div {
  border-bottom: 1px solid #f1f2f2;
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
}

:global .popover {
  border-radius: 0px;
  font-family: 'brandon-grotesque';
}

.popoverInner h3 {
  background-color: #f1f2f2;
  font-size: 0.875rem;
}

:global .popover .arrow::after {
  border-bottom-color: #f1f2f2 !important;
}
