td.number {
  color: #000;
  font-size: 0.875em;
  font-weight: bold;
}
td.price {
  color: #000;
  font-weight: bold;
  letter-spacing: 0.15em;
}
td.status {
  color: #000;
  font-size: 0.625em;
  font-weight: bold;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
td.toggler {
  font-size: 1em;
}

td.rowToggle {
  background: #f1f2f2;
  font-size: 1em;
  position: relative;
}

.page {
  position: relative;
}

.pageNav {
  margin: 0 0 0 -1em;
}

.status {
  padding: 0.6em 1.2em;
}
.ordersTable tr:hover td .status {
  background: #fff;
}

.ordersTable {
  transition: opacity 250ms;
}

.ordersTableLoading {
  opacity: 10%;
}

.table {
  padding: 1.25rem 0 0;
}

.tip {
  left: calc(15px + 15em);
  position: absolute;
  right: 15px;
  text-align: right;
  top: -25.5px;
}

.toggled {
  position: absolute;
  right: 1em;
  top: 1.3em;
}

.emptyState {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.emptyStateHidden {
  opacity: 0;
}
