.wrapper h2 {
  margin-bottom: 2em;
  line-height: 1;
}

.tooltip {
  margin-left: 0.3em;
  display: inline-flex;
}

.button {
  bottom: 2em;
  font-weight: 600;
  letter-spacing: 0.12em;
  border: none;
  background-color: transparent;
  padding: 0;
  margin-bottom: 0.5em;
  color: #b79961;
}

.button:hover,
.button:active,
.button:focus {
  color: #000 !important;
  background-color: transparent !important;
  border: none;
  box-shadow: none !important;
}

.button svg {
  height: 1.5em;
  width: 1.5em;
  color: #b79961;
}

.button:hover svg {
  color: black;
}

.colLead {
  display: flex;
  align-items: center;
}

.parcelInput {
  margin-bottom: 2em;
  margin-right: 14px;
}

.parcelInputLight input {
  background-color: white;
}

.parcelName {
  color: #000;
  font-size: 0.85em;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-right: 1em;
  text-transform: uppercase;
  white-space: nowrap;
}

.row {
  position: relative;

  max-width: 700px;
}

.row > div:first-child {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr 6fr 1fr;
}

.amountCurrency {
  margin-bottom: 2em;
  margin-top: -1em;
}

.row label {
  color: #9d9e9e;
  font-size: 0.625em;
}

.title {
  display: flex;
}

.row label.title {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 575px) {
  .row {
    display: block;
    margin-bottom: 3em;
  }

  .parcelName {
    margin-bottom: 1em !important;
  }
}
