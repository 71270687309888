.container {
  background: #f1f2f2;
  font-family: 'brandon-grotesque';
  padding: 1.5rem;
}
.submitRow {
  display: flex;
  justify-content: flex-end;
}
.fileInputsTitleA {
  font-weight: normal;
  display: block;
}
.fileInputTitle {
  margin-bottom: 0.5rem;
}

.rate {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.rateName {
  font-size: 0.875rem;
  margin-top: -0.1875rem;
}

.ratePrice {
  font-size: 0.875rem;
}

.rateDescription {
  font-size: 0.625rem;
}

.rateError {
  background-color: var(--main-pink-color);
  border: 1px solid var(--main-red-color);
  color: white;
  margin: 5px 0;
  padding: 5px;
}

.fileInputs {
  display: flex;
  gap: 4rem;
}

.checkbox {
  margin-top: 1.5rem;
}

.textInput {
  width: calc(100% / 4);
  margin-bottom: 1.5rem;
  display: block;
}

.textInput:not(:first-child) {
  margin-top: 1.5rem;
}

.textInput input {
  background-color: #fff;
}

.shipErrors {
  margin-top: 3.5rem;
}
