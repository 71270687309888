.table {
  font-size: 1em;
}

.table tbody tr {
  background: #fff;
}

.table td,
.table th {
  vertical-align: middle;
  font-size: 0.875rem;
}

.table img {
  max-height: 100px;
}

.table a {
  word-wrap: none;
}

.tooltip {
  margin-left: 4px;
  margin-top: 6px;
  display: inline;
}

.exportBtn {
  margin-bottom: 10px;
}

.product {
  display: flex;
  align-items: center;
}

.product img {
  margin-right: 10px;
}

.productLinkWrapper {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
