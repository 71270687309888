.btn {
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-top: 0.4em solid #585b5e;
  cursor: pointer;
  height: 0.4em;
  margin: -0.2em 0 0;
  position: absolute;
  right: 0.4em;
  top: 50%;
  width: 0.4em;
}

.input {
  position: relative;
}

.inputField {
  padding-right: 1.5em;
}
