.diagramWrap {
  width: 100%;
}

.h2 {
  margin: 3em 0 0;
}
.wrap .h2 {
  font-size: 0.8125em;
  letter-spacing: 0.15em;
}

.topProducts {
  margin: 4.5em -15px 0;
}

.topProductsHd {
  display: flex;
  align-items: center;
  margin: 0 0 3.25em;
}

.wrap .topProductsHd {
  font-size: 0.875em;
}

.topProductsHd  {
  display: flex;
  align-items: center;
}

.topProductsHd h2 {
  margin: 0 0.5em 0 0;
}

.topProductsHd a {
  margin-left: 1em;
}

.instructions {
  margin-bottom: 3em !important;
}

.views {
  align-items: center;
  display: flex;
  margin: 2.625em 0 0;
}
.views .viewsProductViews {
  margin-right: 1em;
  min-width: auto;
}
.viewsProductViews > li {
  margin-bottom: 0;
}

@media screen and (max-width: 575px) {
  .topProductsHd {
    margin-bottom: 1.5em;
  }

  .topProductsList {
    margin: 0 -15px 1.5em;
  }
}

@media screen and (max-width: 600px) {
  .views {
    display: block;
  }

  .viewsProductViews {
    margin-bottom: 2em;
  }
}
