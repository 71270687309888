.btn,
.icon {
  align-items: center;
  display: inline-flex;
}

.btn {
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.5;
}

.icon {
  margin: 0 0 0 0.1em;
}
