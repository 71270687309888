.pageNav {
  margin: 0 0 0.5em -1em;
}

.orderLink {
  display: block;
}

.input {
  display: inline-block;
  padding: 6px 8px;
  border: 1px solid var(--main-bg-color) !important;
  box-sizing: border-box;
  color: black;
  height: 36px;
  background-color: white;
  font-size: inherit;
  width: 200px;
}

.button {
  margin: 0 15px;
  margin-top: -1px;
  width: 175px;
}

.success,
.error {
  margin-top: 5px;
}

.success {
  color: green;
}

.error {
  color: red;
}
