.btnDeleteField {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.inputElement {
  display: inline-block;
  margin-top: 0.5em;
  vertical-align: top;
}
.inputElement:first-child {
  margin-top: 0;
}

.col-sm-5 {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 0;
  padding-right: 0;
}

.col-sm-2 {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 4px;
}

.col-sm-12 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.list-group-item {
  padding: 3px 1.25rem;
  border: none;
}

li p {
  width: 42%;
  padding: 0;
  display: inline-block;
}

.rowAdd {
  margin: 1em 0 1em -15px;
}
.rowAdd > div {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.vendorInfo {
  display: flex;
  justify-content: space-between;
}

.vendorInfoKey {
  display: inline-block;
  margin: 0;
  font-weight: bold;
  vertical-align: top;
  width: 60%;
}

.vendorInfoValue {
  align-items: center;
  display: flex;
  padding: 0;
  margin: 0;
}
.vendorInfo .vendorInfoValue {
  width: auto;
}

.col-sm-5:first-child {
  padding-left: 15px;
}

.myRow {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.updateInputDiv {
  width: 40%;
  display: inline-block;
}

.idInputDiv {
  display: inline-block;
  vertical-align: top;
  width: 60%;
}

.idInput {
  display: inline-block;
  margin: 0 0 0.5em;
  vertical-align: top;
  width: 100%;
  border-color: var(--main-bg-color);
}

.list-group-item {
  margin-top: 10px;
}

.label {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.labelFile {
  margin-top: 12px;
}

.rowAddField {
  display: flex;
  padding-left: 0;
  padding-right: 0;
}
.rowAddField > div {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.rowAddField .vendorInfoKey {
  margin-top: 0.5em;
  width: 100%;
}
.rowAddField .vendorInfoKey:first-child {
  margin-top: 0;
}

.inputFile {
  margin-bottom: 16px;
}

.mandatoryPackagingField {
  margin-bottom: 1em;
}

.mandatoryPackagingField label {
  margin-bottom: 0;
}
