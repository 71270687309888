.button {
  margin: 0.5em 0 0;
}
.button input[type='radio'] {
  position: static;
}

.buttonPending {
  margin-top: 0.5;
}

.gIconOk {
  color: #0f0;
  display: inline-block;
  font-size: 2em;
  margin: -0.3em 0 0 0.5em;
}

.planStatus {
  color: #f00;
  font-size: 0.75em;
  text-transform: uppercase;
}

.planStatusLbl {
  margin: 0 0 0 0.25em;
  position: relative;
}
.planStatusLblMsg {
  background: #fff;
  border: 1px solid #000;
  border-radius: 0.25em;
  font-size: 0.75em;
  padding: 0.1em 0.25em 0;
  position: absolute;
  text-transform: uppercase;
  white-space: nowrap;
}

.price {
  font-size: 0.85em;
}

.wrap h1 {
  margin: 0;
}
.wrap p {
  margin: 0.5em 0 0;
}
