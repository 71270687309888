.error {
  color: #f00;
  font-size: 1.3em;
  line-height: 0.25;
  margin: 0 0 0 0.1em;
}

.main {
  margin: 0 auto;
  text-align: left;
  color: var(--main-text);
}

.pageNav {
  margin: 0 -15px 0.5em;
}

.titleRow {
  font-size: 24px;
  font-weight: bold;
  height: 60px;
  line-height: 1.2;
}

.artemestPickupRow {
  margin-bottom: 2em;
}

:global(.custom-checkbox .custom-control-label::before) {
  border-radius: 0;
}

:global(.custom-checkbox .custom-control-label::after) {
  top: 0;
}

:global(.custom-checkbox
    .custom-control-input:checked
    ~ div
    .custom-control-label::after) {
  background-position: 50%;
}

:global(.custom-checkbox
    .custom-control-input:checked
    ~ div
    .custom-control-label::after) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
