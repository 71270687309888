.cnt {
  align-items: center;
  display: flex;
  margin: 0.75em 0 0;
}

.content {
  color: #000;
  font-family: 'BentonModDisp SB';
  font-size: 2.25em;
  line-height: 1;
  margin: 0 0 0 0.44em;
  width: 100%;
}

.icon {
  align-items: center;
  color: #e6e7e8;
  display: flex;
}

.list > li {
  margin-bottom: 1.5em;
}
.list > li p {
  display: block;
}

.mod1 {
  text-transform: uppercase;
}

.mod1 .content {
  font-family: 'brandon-grotesque', sans-serif;
  font-size: 1.5em;
  letter-spacing: 0.15em;
  line-height: 1;
  margin-left: 0.667em;
}

.title {
  color: #9d9e9e;
  font-size: 0.625em;
  letter-spacing: 0.129em;
  line-height: 1.6em;
  text-transform: uppercase;
  width: auto;
}
