.descriptionDiv {
  text-align: left;
}

.descTitle {
  font-size: 24px;
  font-weight: bold;
}

.desc {
  font-size: 18px;
}

.fileInput {
  margin: 0 0 1em;
}

.instaInput {
  margin: 30px 0;
  margin-top: 30px;
  text-align: left;
}

.instaInput label {
  font-size: 24px;
  font-weight: normal;
}

.vendorName {
  font-size: 28px;
  font-weight: bold;
  margin: 10px 0;
}

.mainRow {
  margin: 30px -15px 0;
}

.image {
  width: 100%;
}

.imageGallery {
  margin: 10px 0 20px 0;
}

.imageCol {
  position: relative;
}

.removeImage {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  transition: 0.2s;
}

.removeImage:hover {
  background-color: var(--main-border-color);
}
