:root {
  --main-03-color: rgba(183, 153, 97, 0.3);
  --main-04-color: rgba(183, 153, 97, 0.4);
  --main-07-color: rgba(183, 153, 97, 0.7);
  --main-bg-color: rgba(183, 153, 97, 1);
  --main-green-color: #407d41;
  --main-lightGreen-color: #56c65b;
  --main-success-color: #0c0;
  --main-lightBlue-color: #32aa9d;
  --main-blue-color: #2196f3;
  --main-pink-color: #d9534f;
  --main-red-color: #f63636;
  --main-darkRed-color: #d43f3a;
  --loading-color: rgba(240, 201, 126, 0.2);
  --loading-sec-color: #73603d;
  --loading-brown-color: #3f3521;
  --main-golden: rgb(250, 190, 58);
  --main-border-color: #ababab;
  --file-input-bg-color: #eeeeee;
  --main-text: #2d2d2d;
  --main-disabled-color: #919191;
  --main-bg-light: #f8f9fa;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*padding: 50px;*/
  font-family: 'brandon-grotesque';
  font-size: 16px;
  color: var(--main-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #000;
  text-decoration: underline;
}

a:focus,
a:hover {
  color: var(--main-bg-color);
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

#products,
#artisans {
  margin-top: 60px;
}

#product-image {
  max-width: 600px;
  max-height: 400px;
}

#product-form label.error {
  color: #c00;
}

#product-form input.error {
  border: 1px solid #c00;
}

#productDropdown {
  -webkit-column-count: 3;
  column-count: 3;
  -moz-column-count: 3;
}

.panel-heading {
  border-color: #eff2f7;
  font-size: 16px;
  font-weight: 300;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: 15px;
}

.task-content {
  margin-bottom: 30px;
}

.tasks-widget .task-list {
  padding: 0;
  margin: 0;
}

.tasks-widget .task-list > li {
  position: relative;
  padding: 10px 5px;
  border-bottom: 1px dashed #eaeaea;
}

ul li {
  list-style: none;
}

.tasks-widget .task-list li > .task-checkbox {
  float: left;
  width: 30px;
}

input[type='checkbox'],
input[type='radio'] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input,
textarea,
select,
button {
  outline: none !important;
  border: 1px solid #666;
}

.btn-success {
  background-color: var(--main-lightGreen-color);
  border-color: var(--main-lightGreen-color);
  color: #ffffff;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid;
  border-radius: 0;
  text-decoration: none;
}

.btn-primary {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  text-transform: uppercase;
}

.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary.hover,
.btn-primary:disabled,
.open > .dropdown-toggle.btn-primary {
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.btn-secondary {
  text-transform: uppercase;
  border: 1px solid #000;
  border-radius: 2px;
}

label.error {
  color: #c00;
}

.form-control {
  border-radius: 0;
}

#typeahead {
  margin: 0;
  padding: 0;
}

.tt-menu {
  /* UPDATE: newer versions use tt-menu instead of tt-dropdown-menu */
  width: 422px;
  margin-top: 12px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
}

#onboardingReview #product-cont .reviewable {
  text-align: center;
}

#onboardingReview #product-cont img {
  max-height: 500px;
}

#onboardingReview #product-cont .reviewable img {
  width: 480px;
  padding: 0 10px;
}

#onboardingReview #product-cont div .actions h4 {
  height: 35px;
  line-height: 35px;
}

#onboardingReview #product-cont div.reviewable {
  padding-bottom: 10px;
  margin-bottom: 20px;
}

#onboardingReview #product-cont .approve span.glyphicon,
#onboardingReview #product-cont .reject span.glyphicon {
  font-size: 50px;
  color: #aaa;
  padding: 10px;
}

#onboardingReview #product-cont span.glyphicon {
  font-size: 35px;
  color: #aaa;
  padding: 10px;
}

#onboardingReview #product-cont span.glyphicon:hover {
  color: #444;
}

#onboardingReview #product-cont .ok span.glyphicon-ok-sign {
  color: var(--main-success-color);
}

#onboardingReview #product-cont .done span.glyphicon-ok-sign {
  color: #00c;
}

#onboardingReview #product-cont .ko span.glyphicon-remove-sign {
  color: #c00;
}

#onboardingReview #product-cont #approve.on span {
  color: var(--main-success-color);
}

#onboardingReview #product-cont #reject.on span {
  color: #c00;
}

#artisans .hasSeparateInvoice {
  display: none;
}

.successText {
  color: var(--main-success-color);
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ div .custom-control-label::before {
  border-color: var(--main-bg-color);
  background-color: var(--main-bg-color);
}

.custom-switch .custom-control-label::before {
  border-radius: 0;
}

.custom-switch .custom-control-label::after {
  border-radius: 0;
}

.valid-feedback {
  color: var(--main-bg-color);
  font-size: 1rem;
}

.was-validated .form-control:valid {
  border-color: var(--main-bg-color);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: var(--main-bg-color);
  box-shadow: 0 0 0 0.2rem var(--main-03-color);
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: var(--main-bg-color);
  box-shadow: 0 0 0 0.2rem var(--main-03-color);
}

.row {
  margin: 0 auto;
  min-width: 100%;
}

.input-group > .form-control-plaintext {
  border-color: var(--main-bg-color);
}

.image {
  width: 100%;
}

.customInput {
  width: 49%;
}

body {
  background-color: var(--main-bg-light);
}

.errorText {
  color: var(--main-red-color);
  margin: 0;
  display: block;
  position: relative;
  bottom: 15px;
  right: 25px;
  font-size: 80%;
}

.paintItRed {
  color: var(--main-red-color);
}

.labelText {
  font-size: 80%;
  position: relative;
  bottom: 15px;
  left: 20%;
}
