.flex {
  display: flex;
  align-items: center;
}

.pages {
  margin-right: 1rem;
}

:global .react-pdf__Page__textContent {
  transform: none !important;
  top: 0 !important;
  left: 0 !important;
}
