.pageNav {
  margin-left: -1em;
}

.topMenuCnt {
  padding: 1px 0 0;
  position: relative;
  display: flex;
  margin-top: 1.5em;
  margin-bottom: 2em;
  justify-content: space-between;
  align-items: center;
}

.topMenu {
  background: #f0f0f0;
  padding: 5px;
  border-radius: 12px;
  font-size: 0.875em;
}
.topMenu > li {
  margin: 0;
  padding: 5px 16px;
  border-radius: 12px;
}

.topMenu > li a:hover {
  text-decoration: none;
  font-weight: bold;
}

.active {
  background-color: white;
}


@media screen and (max-width: 670px) {
  .topMenu {
    margin-top: 1em;
    position: static;
  }
}
