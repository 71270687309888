.buttons {
  align-content: center;
  display: flex;
  justify-content: space-between;
  margin: 1em 0 0;
}
.buttons > button:first-child {
  margin-right: 0.5em;
}

.fieldsList > div {
  margin-bottom: 1em;
}
