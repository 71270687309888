.buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 1em;
}
.buttons > button {
  width: 48%;
}

.colRight {
  padding-top: 1.7em;
}
.noMargin {
  margin: 0 -15px;
}
.daysInput {
  padding-bottom: 1.14em !important;
  padding-top: 1.14em !important;
  width: 5em !important;
}

.leadTimeInput {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.leadTimeInput label {
  margin-right: 0.5em;
  margin-bottom: 0;
  text-transform: none;
  font-size: 0.8125em;
}

.description {
  min-height: 11em;
}

.field {
  margin: 0 0 2em;
}

.fileInputs {
  margin: 2em 0 0;
}

.form h2 {
  margin-bottom: 1.5em;
}

.onboardingImage {
  margin: 0 0 1em;
}

.popup {
  cursor: pointer;
}

.row,
.row1_1 {
  padding-bottom: 2em;
  padding-top: 2em;
  position: relative;
}
.row:before,
.row1_1:before {
  border-top: 1px solid #d8d8d8;
  content: '';
  left: 15px;
  position: absolute;
  right: 15px;
  top: 0;
}

.row1 {
  padding-bottom: 2em;
}
.row1_1 {
  padding-bottom: 0;
}

.rowMargins {
  margin-left: -15px;
  margin-right: -15px;
}

.packagingTime {
  width: 10em;
}

.suitableForContract {
  margin-bottom: 1.5em;
}
.suitableForContract label {
  margin-bottom: 0.2em;
}

.verticalCenter {
  align-items: center;
  display: flex;
  margin-bottom: 1em;
}
.saveAdmin {
  width: 100%;
}
.saveAdminWrapper {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .colRight {
    padding-top: 0;
  }
}

.parcelsError {
  display: block;
  margin-top: 1em;
  font-size: 100%;
}

.tooltip {
  display: inline-flex;
  margin-left: 0.3em;
  margin-bottom: 1px;
}
