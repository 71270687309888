.alignBottom > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.field {
  border-color: var(--main-bg-color);
}

.list {
  margin-left: -15px;
  margin-right: -15px;
  width: 100%;
}
.list > div {
  margin: 0 0 2em;
}
.list label {
  margin: 0 0 0.3125em;
}

.list > .title {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.list > .title .labelAfter {
  display: inline-flex;
  margin-left: 0.3em;
}

.list.topMarginLess > div {
  margin-bottom: 1em;
}

.range {
  display: flex;
  flex-wrap: wrap;
}

.range > div {
  width: 47%;
}

.range > span {
  width: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
}
