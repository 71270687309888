@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.body {
  position: absolute;
  background: #fff;
  width: 100%;
  background: linear-gradient(
      to right bottom,
      var(--main-03-color),
      var(--main-03-color)
    ),
    url('../../static/images/art.jpeg') 0 0 / cover no-repeat;
  min-height: 100%;
  padding-bottom: 100px;
}

.mainDiv {
  text-align: left;
  background: #fff;
  padding: 30px;
  margin-top: 5%;
}

.mainRow {
  margin: 0;
}

.loginButtonDiv {
  margin: 15px auto 0;
  text-align: center;
  width: 100%;
}

.loginButton {
  padding: 10px 20%;
  margin-top: 5%;
}

.privacyPolicy {
  margin: 0.25em 0 0;
  text-align: center;
}

.txtColor {
  color: #afafaf;
}

.title {
  color: #afafaf;
  text-align: center;
  margin-bottom: 40px;
}

.logo {
  margin: 5% 0 5% 25%;
}

.error {
  width: 100%;
  background-color: var(--main-darkRed-color);
  padding: 20px 20px;
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
}

.div {
  z-index: 2;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  min-height: 110px;
  background: linear-gradient(rgba(183, 153, 97, 0), rgba(183, 54, 51, 0.2));
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}

.copyRight {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
}

.copyRightP {
  color: var(--loading-brown-color);
  text-shadow: 2px 2px var(--main-04-color);
}

.resetPasswordForm {
  margin: 1em 0 0;
}
