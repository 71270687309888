.children {
  padding-left: 0;
  padding-right: 0;
}

.fileInputsTitle {
  display: block;
  width: auto;
}

.fileInputsTitleA {
  font-weight: normal;
  margin: 0 0 0 0.5em;
}

.form {
  margin: 1em 0 0;
}

.otherImages {
  margin: 0 -15px;
}
.otherImagesWrap > div {
  margin-bottom: 2em;
}

.otherProducts {
  margin: 2.5em 0 0;
}

.page {
  padding: 0 0 3em;
}

.page .actionsTop {
  margin-right: 0;
}

.border {
  border: 0.9375rem solid;
  position: fixed;
  left: 0;
  width: 15px;
  height: 100vh;
  top: 0;
}
@media screen and (max-width: 768px) {
  .border {
    border: 0.3125rem solid;
    width: 0.3125rem;
  }
}
